import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import React, { useEffect, useState } from 'react';


const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  marginLeft:-200,
  width: 400,
  maxHeight: 600,
  overflow: "auto",
  bgcolor: 'background.paper',
  border: '1px solid #ddd',
  boxShadow: 24,
  p: 2,
};



export default function Popup(props) {

  const [open, setOpen] = React.useState(props.open);

  useEffect(()=>{
    console.log(props)
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => props.setOpen(false);

  return (
    <div className="product-list">
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                {props.heading}
            </Typography>
            {props.children}
            </Box>
        </Modal>
    </div>
  );
}
