import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import {getNestedCategories, deleteCategory, updateCategory} from '../../http/category-requests';
import './style.scss';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

function preventDefault(event) {
  event.preventDefault();
}


export default function CategoryList() {

  const [rows, setRows] = useState([]);
  useEffect(() => {
      getRows();
  }, []);

  const getRows = () => {
    getNestedCategories().then(resp => {
      console.log(resp);
      setRows(resp);
    }, err => {
      console.log(err);
    })
  }

  const deleteRow = (id) => {
    deleteCategory(id).then(resp => {
      console.log(resp);
      getRows();
    }, err => {
      console.log(err);
    })
  }

  return (
    <div className="category-list">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Slug</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <React.Fragment>
            <TableRow key={row.id} className="level1">
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.slug}</TableCell>
              <TableCell><Moment date={row.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell>{row.updated_at != null && <Moment date={row.updated_at} format="DD-MM-YY hh:mm A" />}</TableCell>
              <TableCell>
                <IconButton aria-label="show 4 new mails" className="text-danger" onClick={(e) => deleteRow(row.id)}>
                  <DeleteIcon />
                </IconButton>
                <Link href={`/categories/edit/${row.id}`}><IconButton aria-label="show 4 new mails" className="text-info">
                  <EditIcon />
                </IconButton></Link>
              </TableCell>
            </TableRow>
            {
              row.children.map((row2) => {
                return <React.Fragment>
                <TableRow key={row2.id} className="level2">
                <TableCell>{row2.title}</TableCell>
                <TableCell>{row2.slug}</TableCell>
                <TableCell><Moment date={row2.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
                <TableCell>{row2.updated_at != null && <Moment date={row2.updated_at} format="DD-MM-YY hh:mm A" />}</TableCell>
                <TableCell>
                  <IconButton aria-label="show 4 new mails" className="text-danger" onClick={(e) => deleteRow(row2.id)}>
                    <DeleteIcon />
                  </IconButton>
                  <Link href={`/categories/edit/${row2.id}`}><IconButton aria-label="show 4 new mails" className="text-info">
                    <EditIcon />
                  </IconButton></Link>
                </TableCell>
                </TableRow>
                {
                  row2.children.map((row3) => {
                   return <TableRow key={row3.id} className="level3">
                    <TableCell>{row3.title}</TableCell>
                    <TableCell>{row3.slug}</TableCell>
                    <TableCell><Moment date={row3.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
                    <TableCell>{row3.updated_at != null && <Moment date={row3.updated_at} format="DD-MM-YY hh:mm A" />}</TableCell>
                    <TableCell>
                      <IconButton aria-label="show 4 new mails" className="text-danger" onClick={(e) => deleteRow(row3.id)}>
                        <DeleteIcon />
                      </IconButton>
                      <Link href={`/categories/edit/${row3.id}`}><IconButton aria-label="show 4 new mails" className="text-info">
                        <EditIcon />
                      </IconButton></Link>
                    </TableCell>
                    </TableRow>
                  })
                }
              </React.Fragment>
              })
            }
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
