import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Visibility from '@material-ui/icons/Visibility';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import RowPopup from '../../components/Popup/row';
import { getAllTransactions } from '../../http/extra-requests';

import './style.scss';



export default function TransactionList() {
  const { id } = useParams();
  const [rows, setRows] = useState([]);
  const [row, setRow] = useState(null);
  const [popup, setPopup] = React.useState(false);
  
  useEffect(() => {
      getRows();
  }, []);

  const getRows = () => {
    getAllTransactions({id}).then(resp => {
      console.log(resp);
      setRows(resp);
    }, err => {
      console.log(err);
    })
  }

  const viewRow = (row) => {
    setRow(row);
    setPopup(true);
  }

  return (
    <div className="product-list">
      <RowPopup open={popup} setOpen={(v) => setPopup(v)} heading="Details" row={row} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Order ID</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} className="level1">
              <TableCell>{row.username}<br/><span className='text-primary'>{row.userphone}</span></TableCell>
              <TableCell>{row.order_id}</TableCell>
              <TableCell>₹{row.order_amt}</TableCell>
              <TableCell>
                {row.tx_status == "SUCCESS" && <div className='text-success'>{row.tx_status}</div>}
                {row.tx_status == "FAILED" && <div className='text-danger'>{row.tx_status}</div>}
                {row.tx_status != "SUCCESS" && row.tx_status != "FAILED" && <div className='text-primary'>{row.tx_status}</div>}
              </TableCell>
              <TableCell><Moment date={row.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell>{row.last_updated_at != null && <Moment date={row.last_updated_at} format="DD-MM-YY hh:mm A" />}</TableCell>
              <TableCell>
                <IconButton color="secondary" onClick={(e) => viewRow(row)}>
                  <Visibility />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
