import {
    httpGet,
    httpPost,
    httpPut,
    httpMultipart,
    httpDelete
} from "./http-service";
import config from '../config';

export const upload = (data) => {
    return new Promise((resolve, reject) => {
        httpMultipart(config.apiBase + "media", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};