import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';


export const getOneDeliverySettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "deliverysettings/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};



export const updateDeliverySettings = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "deliverysettings/"+data.id, false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};
