import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import {getAllOrders, updateOrderStatus} from '../../http/order-requests';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
  createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
  createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
  createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
  createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
];

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function UserOrders() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = () => {
    getAllOrders({limit: 5}).then(resp => {
      setRows(resp)
    }, err => {
      console.log(err);
    })
  };


  const updateStatus = (id, val) =>{
    console.log(id, val);
    updateOrderStatus({id, status: val}).then(resp => {
      getOrders();
    }, err => {
      console.log(err);
      // setAlert(err.data.message);
    })
  }

  return (
    <React.Fragment>
      <Title>Recent Orders</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 && rows.map((r, i) => {
              return <TableRow key={i}>
              <TableCell>{r.order_id}</TableCell>
              <TableCell>{r.title}</TableCell>
              <TableCell>{r.qty}</TableCell>
              <TableCell>₹{r.total}</TableCell>
              <TableCell><Moment date={r.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell>
                {
                  r.updated_at != null && <Moment date={r.updated_at} format="DD-MM-YY hh:mm A" />
                }
              </TableCell>
              <TableCell>
                {
                  r.status == 0 ?
                  <span className="text-primary">Placed</span>
                  :
                  r.status == 1 ?
                  <span className="text-secondary">Dispatched</span>
                  :
                  r.status == 2 ? 
                  <span className="text-success">Delivered</span>
                  :
                  <span className="text-danger">Cancelled</span>
                }
              </TableCell>
              <TableCell>
                {/* <Link to={"/orders/invoice/"+r.order_id}><IconButton aria-label="show 4 new mails" className="text-primary">
                  <VisibilityIcon />
                </IconButton></Link> */}
                {/* <select onChange={(e) => updateStatus(r.order_id, e.target.value)} value={r.status}>
                  <option value="0">Placed</option>
                  <option value="1">Dispatched</option>
                  <option value="2">Delivered</option>
                  <option value="-1">Cancelled</option>
                </select> */}
              </TableCell>
            </TableRow>
            })
          }
        </TableBody>
      </Table>
    
      <div className={classes.seeMore}>
        <Link color="primary" to="/orders">
          See more orders
        </Link>
      </div>
    </React.Fragment>
  );
}
