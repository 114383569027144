import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';

export const getAllKyc = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "all-kyc", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const deleteUser = (data) => {
    return new Promise((resolve, reject) => {
        httpDelete(config.apiBase + "users/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updateKyc = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "kyc/"+data.id, false, {status : data.status})
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const getAllGoals = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "admin/goals", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getAllTransactions = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "admin/transactions", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getAllTickets = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "admin/tickets", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getAllBanks = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "admin/banks", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getAllWithdrawals = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "admin/withdrawals", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getLastDraw = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "admin/draws", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getRandomTicket = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "admin/random-ticket", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const submitDraw = (data) => {
    return new Promise((resolve, reject) => {
        httpPost(config.apiBase + "admin/draw", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getStats = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "admin/stats", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const viewDraw = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "admin/draw", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};