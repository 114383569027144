import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import EditDeliverySettings from './edit';

export default function DeliverySettings() {
  const [updatedAt, setUpdatedAt] = useState("");
  return (
    <div className="root">
      <Header />
      <main className="content">
        <div className="verticle-spacer" />
        <Container maxWidth="lg" className="container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className="paper mb-2">
              <Box flexDirection="row" display="flex" justifyContent="space-between">
                <h1 className="page-title">Delivery Settings</h1>
                <Box>
                  Last updated <span><Moment date={updatedAt} format="DD-MM-YY hh:mm A" /></span>
                </Box>
              </Box>
            </Paper>
            <Paper className="paper">
              <EditDeliverySettings updatedAt={setUpdatedAt} />  
            </Paper>
            </Grid>
          </Grid>
          <Footer />
        </Container>
      </main>
    </div>
  );
}
