import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';

export const addAdmin = (data) => {
    return new Promise((resolve, reject) => {
        httpPost(config.apiBase + "Admins", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getAllAdmins = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "Admins", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const deleteAdmin = (data) => {
    return new Promise((resolve, reject) => {
        httpDelete(config.apiBase + "Admins/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getOneAdmin = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "Admins/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updateAdmin = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "Admins/"+data.id, false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updateWithdrawStatus = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "admin/withdrawals/", true, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

