import React, { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Alert from '../../components/Alert';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ImagePicker from '../../components/ImagePicker';
import {updateGeneralSettings, getOneGeneralSettings} from '../../http/generalsettings-requests';

import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

import {EditorState, convertToRaw, ContentState, convertFromHTML, createFromBlockArray} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import './style.scss';

export default function EditGeneralSettings(props) {
const id = 1;
const [host, setHost] = useState("");
const [port, setPort] = useState("");
const [username, setUsername] = useState("");
const [password, setPassword] = useState("");
const [fromName, setFromName] = useState("");
const [fromEmail, setFromEmail] = useState("");
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState("");


useEffect(() => {
    getSettings();
}, []);

const getSettings = () => {
  getOneGeneralSettings(1).then(resp => {
        setHost(resp.host);
        setPort(resp.port);
        setUsername(resp.username);
        setPassword(resp.password);
        setFromName(resp.from_name);
        setFromEmail(resp.from_email);
        
        props.updatedAt(resp.updated_at);
    }, err => {
        console.log(err);
    })
}

const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    var data = {
        id,
        host,
        port,
        username,
        password,
        fromName,
        fromEmail
    };
    updateGeneralSettings(data).then(resp => {
      if (resp != '') {
          window.location.href = "/settings";
      }
    }, error => {
      console.log(error);
      setAlert(error.data.message);
      setLoading(false);
    });
  };

return (
    
            <ValidatorForm onSubmit={submit}>
                <TextValidator
                    variant="outlined"
                    value = {host}
                    onChange={(e) => {setHost(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="SMTP Host"
                    autoFocus
                />
                <TextValidator
                    variant="outlined"
                    fullWidth
                    type="number"
                    value = {port}
                    onChange={(e) => setPort(e.target.value)}
                    label="SMTP Port"
                    validators={['required']}
                    errorMessages={['This field is required']}
                />
                <TextValidator
                    variant="outlined"
                    fullWidth
                    value = {username}
                    onChange={(e) => setUsername(e.target.value)}
                    label="SMTP Username"
                    validators={['required']}
                    errorMessages={['This field is required']}
                />
                <TextValidator
                    variant="outlined"
                    fullWidth
                    value = {password}
                    onChange={(e) => setPassword(e.target.value)}
                    label="SMTP Password"
                    validators={['required']}
                    errorMessages={['This field is required']}
                />
                <TextValidator
                    variant="outlined"
                    fullWidth
                    value = {fromName}
                    onChange={(e) => setFromName(e.target.value)}
                    label="From Name"
                    validators={['required']}
                    errorMessages={['This field is required']}
                />
                <TextValidator
                    variant="outlined"
                    fullWidth
                    value = {fromEmail}
                    onChange={(e) => setFromEmail(e.target.value)}
                    label="From Email"
                    validators={['required']}
                    errorMessages={['This field is required']}
                />
                {/* <Grid container>
                  <Grid item md={6}>
                  <div className="mb-1 sub-title">Logo:</div>
                <ImagePicker limit={1} id="logo" picked={setLogo} selected={logo} />
                  </Grid>
                  <Grid item md={6}>
                  <div className="mb-1 sub-title">Favicon:</div>
                <ImagePicker limit={1} id="favicon" picked={setFavicon} selected={favicon} />
                  </Grid>
                </Grid>
                

                 */}
               
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Update</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>
            
  );
}
