import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';

export const getAllPaymentSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "paymentsettings", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getNestedPaymentSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "paymentsettings-nested", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getOnePaymentSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "paymentsettings/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const deletePaymentSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpDelete(config.apiBase + "paymentsettings/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updatePaymentSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "paymentsettings/"+data.id, false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const addPaymentSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpPost(config.apiBase + "paymentsettings", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const getTopPaymentSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "paymentsettings-top", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};