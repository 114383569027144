import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Visibility from "@material-ui/icons/Visibility";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import RowPopup from "../../components/Popup/row";
import { getAllTickets } from "../../http/extra-requests";

import "./style.scss";

export default function TicketList() {
  const { id } = useParams();
  const [rows, setRows] = useState(null);
  const [row, setRow] = useState(null);
  const [popup, setPopup] = React.useState(false);

  useEffect(() => {
    getRows();
  }, []);

  const getRows = () => {
    getAllTickets({ id }).then(
      (resp) => {
        if (resp.length > 0) {
          setRows(resp);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const viewRow = (row) => {
    setRow(row);
    setPopup(true);
  };

  return (
    <div className="product-list">
      <RowPopup
        open={popup}
        setOpen={(v) => setPopup(v)}
        heading="Details"
        row={row}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Ticket #</TableCell>
            <TableCell>Is Bonus?</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row) => (
              <TableRow key={row.id} className="level1">
                <TableCell>
                  {row.username}
                  <br />
                  <span className="text-primary">{row.userphone}</span>
                </TableCell>
                <TableCell>{row.ticket_no}</TableCell>
                <TableCell>
                  {row.is_bonus == 1 && <div className="text-success">Yes</div>}
                </TableCell>
                <TableCell>
                  <Moment date={row.created_at} format="DD-MM-YY hh:mm A" />
                </TableCell>
                <TableCell>
                  {row.last_updated_at != null && (
                    <Moment
                      date={row.last_updated_at}
                      format="DD-MM-YY hh:mm A"
                    />
                  )}
                </TableCell>
                <TableCell>
                  <IconButton color="secondary" onClick={(e) => viewRow(row)}>
                    <Visibility />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
}
