import React, { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Alert from '../../components/Alert';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ImagePicker from '../../components/ImagePicker';
import {getTopPages, updatePage, getOnePage} from '../../http/page-requests';

import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

import {EditorState, convertToRaw, ContentState, convertFromHTML, createFromBlockArray} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import config from '../../config';
import './style.scss';

export default function EditPage(props) {
const { id } = useParams();
const [pages, setPages] = useState([]);
const [title, setTitle] = useState("");
const [slug, setSlug] = useState("");
const [thumbnail, setThumbnail] = useState([]);
const [description, setDescription] = useState();
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState("");


useEffect(() => {
    getPage();
}, []);

const getPage = () => {
    getOnePage(id).then(resp => {
        setTitle(resp.title);
        setSlug(resp.slug);
        setThumbnail([resp.thumbnail]);
        setTimeout(function(){
  
          const blocksFromHTML2 = convertFromHTML(resp.content);
          const state2 = ContentState.createFromBlockArray(
            blocksFromHTML2.contentBlocks,
            blocksFromHTML2.entityMap,
          );
          console.log(EditorState.createWithContent(state2));
          setDescription(EditorState.createWithContent(state2));
        }, 500);
    }, err => {
        console.log(err);
    })
}

const onEditorStateChange = (highlights) => {
  setDescription(highlights);
};

const uploadImageCallBack = (file) => {    
  const imageObject = {
    file: file,
    localSrc: URL.createObjectURL(file),
  }
  return new Promise(
    (resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    }
  );
}


const hashtagConfig = {
  trigger: '#',
  separator: ' ',
}

const slugify = (txt) => {
    txt = txt.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
    setSlug(txt);
};

const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    const descriptionHtml = convertToRaw(description.getCurrentContent());
    const descriptionMarkup = draftToHtml(
      descriptionHtml, 
      hashtagConfig
    );
    var data = {
        id,
        title,
        slug,
        thumbnail,
        description: descriptionMarkup
    };
    updatePage(data).then(resp => {
      if (resp != '') {
          window.location.href = "/pages";
      }
    }, error => {
      console.log(error);
      setAlert(error.data.message);
      setLoading(false);
    });
  };

return (
    <div className="root">
      <Header />
      <main className="content">
        <Alert alert={alert} setAlert={setAlert} />
        <div className="verticle-spacer" />
        <Container maxWidth="lg" className="container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className="paper mb-2">
              <Box flexDirection="row" display="flex" justifyContent="space-between">
                <h1 className="page-title">Edit Page</h1>
                <Link to="/pages"><Button variant="contained" color="secondary">Back</Button></Link>
              </Box>
            </Paper>
            <Paper className="paper">
            <ValidatorForm onSubmit={submit}>
                <TextValidator
                    variant="outlined"
                    value = {title}
                    onChange={(e) => {setTitle(e.target.value); slugify(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    id="email"
                    label="Page Title"
                    name="title"
                    autoComplete="title"
                    autoFocus
                />
                <TextValidator
                    variant="outlined"
                    fullWidth
                    name="password"
                    value = {slug}
                    onChange={(e) => setSlug(e.target.value)}
                    label="URL Slug"
                    type="text"
                    id="slug"
                    validators={['required']}
                    errorMessages={['This field is required']}
                    autoComplete="slug"
                />
                <Box bgcolor="success.main" color="success.contrastText" p={2} mb={2}>
                  {config.home+"page/"+slug}
                </Box>

                 <div className="mb-1 mt-2 sub-title">Thumbnail:</div>
                <ImagePicker limit={1} id="page-image" picked={setThumbnail} selected={thumbnail} />
                <Editor
                  editorState={description}
                  onEditorStateChange={onEditorStateChange}    
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
                  }}
                />
                <div className="vertical-spacer">&nbsp;</div>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Update</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>
            </Paper>
            </Grid>
          </Grid>
          <Footer />
        </Container>
      </main>
    </div>
  );
}
