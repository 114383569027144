import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { deleteUser, getAllUsers, updateUser } from '../../http/user-requests';
import { getAllKyc, updateKyc } from '../../http/extra-requests';
import config from '../../config';
import { useParams } from 'react-router-dom';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}



function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function PageList() {
  const classes = useStyles();
  const { id } = useParams();

  const [rows, setRows] = useState([]);
  useEffect(() => {
      getRows();
  }, []);

  const getRows = () => {
    getAllKyc({id}).then(resp => {
      console.log(resp);
      setRows(resp);
    }, err => {
      console.log(err);
    })
  }

  const deleteRow = (id) => {
    deleteUser(id).then(resp => {
      console.log(resp);
      getRows();
    }, err => {
      console.log(err);
    })
  }


  const activateRow = (id) => {
    updateKyc({id: id, status: 1}).then(resp => {
      console.log(resp);
      getRows();
    }, err => {
      console.log(err);
    })
  }

  const deactivateRow = (id) => {
    updateKyc({id: id, status: -1}).then(resp => {
      console.log(resp);
      getRows();
    }, err => {
      console.log(err);
    })
  }

  const getStatus = (st) => {
    switch (st) {
      case 1:
        return '<span className="text-success">Approved</span>';
        break;
      case -1:
        return '<span className="text-danger">Rejected</span>';
        break;
      default:
        return '<span className="text-warning">Pending</span>';
        break;
    }
  }

  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>ID Front</TableCell>
            <TableCell>ID Back</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.username}<br/><span className='text-primary'>{row.userphone}</span></TableCell>
              <TableCell><a href={config.staticBase+row.id_front.file_path}  target="_BLANK"><img src={config.staticBase+row.id_front.thumbnail} className="table-thumbnail" /></a></TableCell>
              <TableCell><a href={config.staticBase+row.id_back.file_path}  target="_BLANK"><img src={config.staticBase+row.id_back.thumbnail} className="table-thumbnail" /></a></TableCell>
              
              <TableCell>{
                row.status == 1 ?
                <span className="text-primary" variant="contained" color="primary">Approved</span>
                :
                row.status == -1 ?

                <span className="text-danger" variant="contained" color="secondary">Rejected</span>
                :
                <span className="text-warning" variant="contained" color="secondary">Pending</span>
                }</TableCell>
              <TableCell><Moment date={row.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell>{row.last_updated_at != null && <Moment date={row.last_updated_at} format="DD-MM-YY hh:mm A" />}</TableCell>
              <TableCell>

              {
                  row.status != 1 ?
                <IconButton aria-label="show 4 new mails" color="primary" onClick={(e) => activateRow(row.id)}>
                <CheckCircleOutlineIcon />
                </IconButton>
                :null
                }
                {
                row.status != -1 ?
                <IconButton aria-label="show 4 new mails" color="secondary" onClick={(e) => deactivateRow(row.id)}>
                <BlockIcon />
                </IconButton>
                :
                null
                }
              {/* <Button variant="contained" color="secondary" className="loginBtn">
                    Login
              </Button> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        {/* <Link color="primary" href="#" onClick={preventDefault}>
          See more orders
        </Link> */}
      </div>
    </React.Fragment>
  );
}
