import axios from 'axios';

const structureQueryParams = params => {
    let queryStrings = "?";
    const keys = Object.keys(params);
    keys.forEach((key, index) => {
        queryStrings += key + "=" + params[key] + "&";
    });
    return queryStrings.trim("&");
};

const setHeaders = () => {
    let headers = {};
    const authToken = localStorage.getItem("admin_auth");
    if (authToken != null) {
        headers["Authorization"] = "Bearer "+authToken;
    }
    return headers;
}

export const httpGet = async (url, attachToken = false, params = null,twitterToken=false) => {
    let queryString = "";
    if (params) {
        queryString = structureQueryParams(params);
    }
    let headers = { "Content-Type": "application/json" };
    if (attachToken) {
        headers = {...headers,...setHeaders()};
    }
    return axios.get(url + queryString, { headers: headers });
};

export const httpPost = async (url, attachToken = false, params = {},twitterToken=false) => {
    let headers = {
        "Content-Type": "application/json"
    };

    if (attachToken) {
        headers = {...headers,...setHeaders()};
    }
    return axios.post(url, params, { headers: headers });
};
export const httpDelete = async (url, attachToken = false, params = {},twitterToken=false) => {
    let headers = {
        "Content-Type": "application/json"
    };

    if (attachToken) {
        headers = {...headers,...setHeaders()};
    }
    return axios.delete(url, {}, { headers: headers });
};

export const httpPut = async (url, attachToken = false, params = {}) => {
    let headers = {
        "Content-Type": "application/json"
    };
    if (attachToken) {
        headers = {...headers,...setHeaders()};
    }
    return axios.put(url, params, { headers: headers });
};

export const httpMultipart = async (url, attachToken = false, params = {}) => {
    let headers = {
        "Content-Type": "multipart/form-data"
    };
    
    if (attachToken) {
        headers = {...headers,...setHeaders()};
    }
    const form_data = new FormData();
    for (const key in params) {
        const value = params[key];
        if (Array.isArray(value)) {
            const arrayKey = `${key}[]`;
            value.forEach(v => {
                form_data.append(arrayKey, v);
            });
        } else{
            form_data.append(key, value);
        }
    }
    return axios.post(url, form_data, { headers: headers });
};