import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import Moment from 'react-moment';
import {getTotalEarnings} from '../../http/order-requests';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Deposits(props) {
  const classes = useStyles();
  const [title, setKey] = useState(props.title);
  const [value, setValue] = useState(props.value);

  const [more, setMore] = useState(props.more);
  // useEffect(() => {
  //   getEarnings();
  // }, []);  

  // const getEarnings = () => {
  //   getTotalEarnings().then(resp => {
  //     if(resp.total != undefined && resp.total != null){
  //     setEarnings(Number(resp.total));  
  //     }
  //   }, err => {

  //   });
  // }

  return (
    <React.Fragment>
      <Title >{title}</Title>
      <Typography component="p" variant="h4">
      {/* ₹ {earnings.toFixed(2)} */}
      {value}
      </Typography>
    
      <div>
      {more && <Link color="primary" href="#" onClick={preventDefault}>
          View More
        </Link>}
      </div>
    </React.Fragment>
  );
}
