import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';

export const getAllGeneralSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "generalsettings", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getNestedGeneralSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "generalsettings-nested", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getOneGeneralSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "generalsettings/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const deleteGeneralSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpDelete(config.apiBase + "generalsettings/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updateGeneralSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "generalsettings/"+data.id, false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const addGeneralSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpPost(config.apiBase + "generalsettings", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const getTopGeneralSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "generalsettings-top", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};