import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';

export const getAllBrands = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "brands", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getNestedBrands = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "brands-nested", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getOneBrand = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "brands/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const deleteBrand = (data) => {
    return new Promise((resolve, reject) => {
        httpDelete(config.apiBase + "brands/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updateBrand = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "brands/"+data.id, false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const addBrand = (data) => {
    return new Promise((resolve, reject) => {
        httpPost(config.apiBase + "brands", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const getTopBrands = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "brands-top", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};