import React from "react";
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import history from './hooks/history';
import PrivateRoute from './hooks/PrivateRoute';
import PublicRoute from './hooks/PublicRoute';

//pages
import Admins from "./pages/Admins";
import AddAdmin from "./pages/Admins/add";
import EditAdmin from "./pages/Admins/edit";
import Agents from "./pages/Agents";
import AddAgent from "./pages/Agents/add";
import EditAgent from "./pages/Agents/edit";
import Brands from "./pages/Brands";
import AddBrand from "./pages/Brands/add";
import EditBrand from "./pages/Brands/edit";
import Categories from "./pages/Categories";
import AddCategory from "./pages/Categories/add";
import EditCategory from "./pages/Categories/edit";
import Dashboard from "./pages/Dashboard";
import DeliverySettings from "./pages/DeliverySettings";
import FrontPage from "./pages/FrontPage";
import AddFrontPage from "./pages/FrontPage/add";
import EditFrontPage from "./pages/FrontPage/edit";
import GeneralSettings from "./pages/GeneralSettings";
import HeaderSettings from "./pages/HeaderSettings";
import KYC from "./pages/KYC";
import Login from './pages/Login';
import Login2 from './pages/Login2';
import Orders from "./pages/Orders";
import EditOrder from "./pages/Orders/edit";
import Invoice from "./pages/Orders/invoice";
import OtherSettings from "./pages/OtherSettings";
import Pages from "./pages/Pages";
import AddPage from "./pages/Pages/add";
import EditPage from "./pages/Pages/edit";
import PaymentSettings from "./pages/PaymentSettings";
import Prizes from "./pages/Prizes";
import AddPrize from "./pages/Prizes/add";
import EditPrize from "./pages/Prizes/edit";
import Ratings from "./pages/Ratings";
import Simulator from "./pages/Simulator";
import Users from "./pages/Users";



import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Goals from "./pages/Goals";
import Transactions from "./pages/Transactions";
import Tickets from "./pages/Tickets";
import Banks from "./pages/Banks";
import Withdrawals from "./pages/Withdrawals";
import Draw from "./pages/Draw";
import AddDraw from "./pages/Draw/add";



const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#27aae1',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#673AB7',
      dark: '#ba000d',
      contrastText: '#fff',
    },
    error: {
      light: '#ff7961',
      main: '#ed4252',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function App() {
  return (
    <Router history={history}>
      <div className="App">
      <MuiThemeProvider theme={theme}>
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/users" component={Users} />
          <PrivateRoute exact path="/admins" component={Admins} />
          <PrivateRoute exact path="/admins/add" component={AddAdmin} />
          <PrivateRoute exact path="/admins/edit/:id" component={EditAdmin} />
          <PrivateRoute exact path="/agents" component={Agents} />
          <PrivateRoute exact path="/agents/add" component={AddAgent} />
          <PrivateRoute exact path="/agents/edit/:id" component={EditAgent} />
          <PrivateRoute exact path="/categories" component={Categories} />
          <PrivateRoute exact path="/categories/add" component={AddCategory} />
          <PrivateRoute exact path="/categories/edit/:id" component={EditCategory} />
          <PrivateRoute exact path="/brands" component={Brands} />
          <PrivateRoute exact path="/brands/add" component={AddBrand} />
          <PrivateRoute exact path="/brands/edit/:id" component={EditBrand} />  
          <PrivateRoute exact path="/pages" component={Pages} />
          <PrivateRoute exact path="/pages/add" component={AddPage} />
          <PrivateRoute exact path="/pages/edit/:id" component={EditPage} />  
          <PrivateRoute exact path="/orders" component={Orders} />
          <PrivateRoute exact path="/orders/invoice/:orderid" component={Invoice} />
          <PrivateRoute exact path="/ratings" component={Ratings} />
          <PrivateRoute exact path="/orders/edit/:id" component={EditOrder} />  
          <PrivateRoute exact path="/settings" component={GeneralSettings} />
          <PrivateRoute exact path="/settings/other" component={OtherSettings} />
          <PrivateRoute exact path="/settings/payment" component={PaymentSettings} />
          <PrivateRoute exact path="/settings/delivery" component={DeliverySettings} />
          <PrivateRoute exact path="/settings/frontpage" component={FrontPage} />
          <PrivateRoute exact path="/settings/frontpage/add" component={AddFrontPage} />
          <PrivateRoute exact path="/settings/frontpage/edit/:id" component={EditFrontPage} /> 
          <PrivateRoute exact path="/simulator" component={Simulator} />
          <PrivateRoute exact path="/settings/header" component={HeaderSettings} />
          <PublicRoute restricted={true} exact path="/login" component={Login} />
          <PublicRoute restricted={false} exact path="/page/:slug" component={Login2} />
          <PrivateRoute exact path="/prizes" component={Prizes} />
          <PrivateRoute exact path="/prizes/add" component={AddPrize} />
          <PrivateRoute exact path="/prizes/edit/:id" component={EditPrize} />

          <PrivateRoute exact path="/kyc/:id" component={KYC} />
          <PrivateRoute exact path="/goals/:id" component={Goals} />
          <PrivateRoute exact path="/transactions/:id" component={Transactions} />
          <PrivateRoute exact path="/tickets/:id" component={Tickets} />
          <PrivateRoute exact path="/banks/:id" component={Banks} />
          <PrivateRoute exact path="/withdrawals/:id" component={Withdrawals} />
          <PrivateRoute exact path="/draws" component={Draw} />
          <PrivateRoute exact path="/draw/add" component={AddDraw} />
        </Switch>
        </MuiThemeProvider>
        
      </div>
    </Router>
  );
}

export default App;
