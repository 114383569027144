import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import React, { useEffect, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ValidatorForm } from 'react-material-ui-form-validator';
import FilterInput from '../../components/FilterInput';
import { getOneHeaderSettings, updateHeaderSettings } from '../../http/headersettings-requests';
import './style.scss';

export default function EditHeaderSettings(props) {
const id = 1;
const [welcome_text, setWelcome] = useState("");
const [marquee_ads, setMarquee] = useState([]);
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState("");


useEffect(() => {
    getSettings();
}, []);
const hashtagConfig = {
    trigger: '#',
    separator: ' ',
  }
const getSettings = () => {
  getOneHeaderSettings(1).then(resp => {
        setMarquee(resp.marquee_ads == "" ? [] : JSON.parse(resp.marquee_ads));
        props.updatedAt(resp.updated_at);
        setTimeout(function(){
            const blocksFromHTML = convertFromHTML(resp.welcome_text == null ? '<p></p>' : resp.welcome_text);
            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap,
            );
            setWelcome(EditorState.createWithContent(state));
        });
    }, err => {
        console.log(err);
    })
}
const onEditorStateChange = (highlights) => {
    setWelcome(highlights);
};
const uploadImageCallBack = (file) => {    
    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    }
    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: imageObject.localSrc } });
      }
    );
}
const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    const copyrightHtml = convertToRaw(welcome_text.getCurrentContent());
    const copyrightMarkup = draftToHtml(
        copyrightHtml, 
      hashtagConfig
    );
    var data = {
        id,
        marquee_ads,
        welcome_text: copyrightMarkup
    };
    updateHeaderSettings(data).then(resp => {
      if (resp != '') {
          window.location.href = "/settings/header";
      }
    }, error => {
      console.log(error);
      setAlert(error.data.message);
      setLoading(false);
    });
  };

return (
    
            <ValidatorForm onSubmit={submit} className="addPage">
             
                <div className="mb-1 sub-title">Marquee Ads:</div>
                <FilterInput limit={10} picked={setMarquee} selected={marquee_ads} tag="marquee_ads" keyLabel="Ad Text" valueLabel="Ad Link"  />
             
                <div className="mb-1 sub-title">Welcome Text:</div>
                <Editor
                  editorState={welcome_text}
                  onEditorStateChange={onEditorStateChange}    
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
                  }}
                />
               <div className="mb-2"></div>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Update</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>
            
  );
}
