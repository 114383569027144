import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../assets/images/logo.png';
import {login} from '../../http/user-requests';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.scss';
import config from '../../config';
import { useParams } from 'react-router-dom';
import { getOnePage } from '../../http/page-requests';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={config.home}>
      {config.name}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const classes = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
class Login2 extends React.Component {
  state = {
    alert: '',
    title: '',
    loading: true,
    content: '',
    username: '',
    password: ''
  }
  componentDidMount = () =>{
    getOnePage(
      this.props.match.params.slug).then(resp => {
      this.setState({
        loading:false,
        content: resp.content,
        title: resp.title
      });
      
    }, err => {
      console.log(err);
    })
  }
  handleChange = (event) => {
    console.log(event.target)
    this.setState({ [event.target.name] : event.target.value });
}

handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  this.setState({
    alert : ''
  })
};

  login = (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    }, () => {
      login(this.state).then(resp => {
        console.log(resp);
        if (resp != '') {
            localStorage.setItem('admin_auth', resp)
            window.location.href = "/";
        }
      }, error => {
        console.log(error);
        this.setState({
          alert: error.data.message,
          loading: false
        })
      });
    })
  }
  render() {
    return (
      <div className="staticPage">
        <Container component="main" maxWidth="md" className='staticDiv'>
        <CssBaseline />
        <h1 >{this.state.title}</h1>
        {
          this.state.loading ? <CircularProgress color="secondary" />: <div dangerouslySetInnerHTML={{__html: this.state.content}}></div>
        }
        
        <Box mt={8}>
          <Copyright />
        </Box>
        </Container>
      </div>
    );
    }
}

export default Login2;