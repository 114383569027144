import { Button, CircularProgress, Container } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Visibility from '@material-ui/icons/Visibility';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import RowPopup from '../../components/Popup/row';
import { getLastDraw, getRandomTicket, submitDraw } from '../../http/extra-requests';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import  {FormControl, InputLabel, Input, FormHelperText}  from '@material-ui/core';
import { getAllPrizes } from '../../http/prize-requests';

import './style.scss';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Alert from '../../components/Alert';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';


export default function AddDraw() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState("");
    const [draw, setDraw] = useState([]);
    const [prizes, setPrizes] = useState([]);
    const [row, setRow] = useState(null);
    const [popup, setPopup] = React.useState(false);
    
    useEffect(() => {
        getRows();
    }, []);
  
    const getRows = () => {
      getLastDraw().then(resp => {
        setDraw(resp);
      }, err => {
        console.log(err);
      })
      getAllPrizes().then(resp => {
        let temp = []
        resp?.forEach((el)=>{
          temp.push({id:el.id, name: el.name, amount: el.amount, shares: el.shares, tickets:[{ticket:""}]});
        });
        setPrizes(temp);
      }, err => {
        console.log(err);
      })
    }

    
  
    const viewRow = (row) => {
      setRow(row);
      setPopup(true);
    }
  
    const submit =(e)=>{
      e.preventDefault();
      let err = 0;
      let tkts= [];
      let finaltkts=[];
      let temp = [...prizes];
      let p = 0;
      temp.forEach((el,i)=>{
        p = Math.round(Number(el.amount)/el.tickets.length);
        el.tickets.forEach((el2,i2)=>{
          if(el2.ticket == null || el2.ticket == "" || tkts.includes(el2.ticket)){
            err = 1;
          }else{
            finaltkts.push({ticket: el2.ticket, amount: p, id: el.id});
          }
          tkts.push(el2.ticket);
          
        })
      })
      if(err == 0){
        console.log(finaltkts);
        submitDraw({winners: finaltkts}).then(res=>{
            console.log(res)
        }, err=>{
            console.log(err)
        })
      }else{
        setAlert("Please unique ticket numbers for all prizes and shares");
      }
    }

    const handleChange =(i, i2, v)=>{
      console.log(i, i2, v)
      let temp = [...prizes];
      try {
        console.log(temp[i]['tickets'][i2])
        temp[i]['tickets'][i2]['ticket'] = v;
        setPrizes(temp);
      } catch (error) {
        console.log(error)
      }
      
    }
  
    const addWinner=(i)=>{
      let temp = [...prizes];
      temp[i].tickets.push({ticket:""});
      setPrizes(temp);
    }
  
    const remove=(i, i2)=>{
      let temp = [...prizes];
      delete temp[i]["tickets"][i2];
      setPrizes(temp);
    }

    const pick = (i, i2) => {
        setLoading(true);
        let temp = [...prizes];
        getRandomTicket().then(resp => {
            if(resp.ticket_no != null){
                console.log(resp.ticket_no)
            temp[i]["tickets"][i2]["ticket"] = resp.ticket_no
            console.log(temp)
            setPrizes(temp);
            }
            setLoading(false);
        }, err => {
          console.log(err);
          setLoading(false);
        })
      }
return (
    <div className="root">
      <Header />
      <main className="content">
        <Alert alert={alert} setAlert={setAlert} />
        <div className="verticle-spacer" />
        <Container maxWidth="lg" className="container">
        <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className="paper mb-2">
              <Box flexDirection="row" display="flex" justifyContent="space-between">
                <h1 className="page-title">New Draw</h1>
                <Link to="/draws"><Button variant="contained" color="secondary">Back</Button></Link>
              </Box>
            </Paper>
            <Paper className="paper">
        <div className="draw">
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={(alert == '') ? false : true}
        autoHideDuration={3000}
        onClose={()=>setAlert("")}
        message={alert}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>setAlert("")}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <RowPopup open={popup} setOpen={(v) => setPopup(v)} heading="Details" row={row} />
      <form onSubmit={submit}>
      {
        prizes?.map((el,i)=>{
          return <div className='mb-2 item'>
            <div className='row'><h3>{el.name}</h3>
              {el.shares > 1 && el.tickets.length < el.shares && <Button type="button" variant="contained" color="dark" className="ml-2" onClick={(e)=>addWinner(i)}>Add Another Winner</Button> }
            </div>
            {el.tickets.map((el2, i2)=>{
            return <div>
              <FormControl>
              <Input placeholder="Ticket #" onChange={(e)=>handleChange(i, i2, e.target.value)} value={prizes[i]["tickets"][i2]['ticket']} />
              </FormControl>
              <Button type="button" variant="contained" color="secondary" className="ml-2" onClick={()=>pick(i, i2)}>
                { !loading && <span>Random Pick</span> }
                { loading && <CircularProgress color="secondary" /> }
              </Button>

              {i2 > 0 && <Button type="button" variant="contained" color="dark" className="ml-2" onClick={()=>remove(i, i2)}>Remove</Button> }
            </div>})}
          </div>
          
        })
      }
      <Button type="submit" variant="contained" color="primary">Submit</Button>
      </form>
    </div></Paper></Grid></Grid>
          <Footer />
        </Container>
      </main>
    </div>
  );
}
