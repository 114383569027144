import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';

export const getAllOrders = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "orders", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getOneOrder = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "orders/"+data.id, true)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updateOrderStatus = (data) => {
    console.log(data);
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "update-order-status", true, data)
            .then(res => {
               
                resolve(res.data);
            })
            .catch(e => {
                console.log(config.apiBase + "update-order-status");
                reject(e.response);
            });
    });
};

export const getTotalEarnings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "total-earnings", true)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const shiprocketPicup = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "shiprocket", true, data)
            .then(res => {
               
                resolve(res.data);
            })
            .catch(e => {
                console.log(config.apiBase + "shiprocket");
                reject(e.response);
            });
    });
};

export const shiprocketCancel = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "shiprocket-cancel", true, data)
            .then(res => {
               
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const shiprocketAWB = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "shiprocket-awb", true, data)
            .then(res => {
               
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const shiprocketPrint = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "shiprocket-print", true, data)
            .then(res => {
               
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};
