import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Alert from '../../components/Alert';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ImagePicker from '../../components/ImagePicker';
import FilterInput from '../../components/FilterInput';
import {addFrontPage} from '../../http/frontpage-requests';
import {getAllCategories} from '../../http/category-requests';

import { render } from 'react-dom';
import {EditorState, convertToRaw} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import './style.scss';

import { ValidatorForm, TextValidator, SelectValidator, } from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function AddFrontPage() {
const [categories, setCategories] = useState([]);
const [category, setCategory] = useState('');
const [sort_order, setOrder] = useState(0);
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState("");
const [type, setType] = useState("");
const [title, setTitle] = useState("");
const [sub_title, setSubTitle] = useState("");
const [position, setPosition] = useState("left");
const [adImage, setAdImage] = useState([]);
const [banners, setBanners] = useState([]);
const [adLimit, setAdLimit] = useState(1);
const [videos, setvideos] = useState([]);

useEffect(() => {
    getCategories();
}, []);

const hashtagConfig = {
  trigger: '#',
  separator: ' ',
}

const getCategories = () => {
    getAllCategories().then(resp => {
        setCategories(resp);
    }, err => {
        console.log(err);
    });
}


const handleLinkChange = (i, v) => {
  var temp = [...adImage];
  temp[i].link = v;
  setAdImage(temp);
}

const handleLinkChange2 = (i, v) => {
  var temp = [...banners];
  temp[i].link = v;
  setBanners(temp);
}

const getLimit = (type) => {
  if(type == 1){
    setAdLimit(2);
  }
  else if(type == 2){
    setAdLimit(3);
  }
  else if(type == 6){
    setAdLimit(6);
  }
  else{
    setAdLimit(1);
  }
};

const submit = (e) => {
  e.preventDefault();
  setLoading(true);

  var data = {
      type,
      position,
      title,
      sub_title,
      banners,
      adImage,
      videos,
      category,
      sort_order
  };
  console.log(data);
  addFrontPage(data).then(resp => {
    if (resp != '') {
        window.location.href = "/settings/frontpage";
    }
  }, error => {
    console.log(error);
    setAlert(error.data.message);
    setLoading(false);
  }); 
};

return (
    <div className="root">
      <Header />
      <main className="content frontPage">
        <Alert alert={alert} setAlert={setAlert} />
        <div className="verticle-spacer" />
        <Container maxWidth="lg" className="container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className="paper mb-2">
              <Box flexDirection="row" display="flex" justifyContent="space-between">
                <h1 className="page-title">Add Front Page Block</h1>
                <Link to="/settings/frontpage"><Button variant="contained" color="secondary">Back</Button></Link>
              </Box>
            </Paper>
            <Paper className="paper">
            <ValidatorForm onSubmit={submit}>
            <SelectValidator
                variant="outlined"
                fullWidth
                value={type}
                onChange={(e) => {
                    setType(e.target.value);
                    getLimit(e.target.value);
                }}
                label="Block Type"
                validators={['required']}
                errorMessages={['This field is required']}
                >
                <MenuItem value={1}>Slider Block</MenuItem>
                <MenuItem value={6}>Offers Block</MenuItem>
                <MenuItem value={7}>Single Ad Block</MenuItem>
                <MenuItem value={8}>Videos Block</MenuItem>
                <MenuItem value={2}>Three Ads Block</MenuItem>
                <MenuItem value={3}>Category Wise Carousel</MenuItem>
                <MenuItem value={4}>Recent Products Carousel</MenuItem>
                <MenuItem value={5}>Featured Products Carousel</MenuItem>
            </SelectValidator>
            <TextValidator
              variant="outlined"
              value = {title}
              onChange={(e) => {setTitle(e.target.value);} }
              validators={['required']}
              errorMessages={['This field is required']}
              fullWidth
              label="Block Title"
            />
            <TextValidator
              variant="outlined"
              value = {sub_title}
              onChange={(e) => {setSubTitle(e.target.value);} }
              validators={['required']}
              errorMessages={['This field is required']}
              fullWidth
              label="Sub Title"
            />
            {
            (type == 6 || type == 3 || type == 4 || type == 5 || type==7) && <div className="mb-3">
            <div className="mb-1 sub-title">Ad Image:</div>
            <ImagePicker limit={adLimit} id="brand-image" picked={setAdImage} size="big" />
            <div className="image-input-group mb-2">
            {
              adImage.map((el, i) => {
                return <input type="text" placeholder="Ad Link" required className="image-input" value={el.link} onChange={(e) => handleLinkChange(i, e.target.value)} />
              })
            }
            </div>
            </div>
            }
{
            type == 8 &&
            <FilterInput limit={10} picked={setvideos} selected={videos} tag="footerBlock1" keyLabel="Title" valueLabel="Youtube Link" />
}
            {
              type == 1 && <div className="mb-3">
                 <div className="mb-1 sub-title">Slider Banners:</div>
                  <ImagePicker limit={25} id="banners" picked={setBanners} size="big" />
                  <div className="image-input-group mb-2">
                  {
                    banners.map((el, i) => {
                      return <input type="text" placeholder="Ad Link" required className="image-input" value={el.link} onChange={(e) => handleLinkChange2(i, e.target.value)} />
                    })
                  }
                  </div>
              </div>
            }
    
            {
              (type == 3 || type == 4 || type == 5 ) && adLimit == 1 && <SelectValidator
                  variant="outlined"
                  fullWidth
                  value={position}
                  onChange={(e) => {
                      setPosition(e.target.value);
                  }}
                  label="Ad Position"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  >
                  <MenuItem value={'left'}>Left</MenuItem>
                  <MenuItem value={'right'}>Right</MenuItem>
              </SelectValidator>
            }
              {
                type == 3 && <SelectValidator
                variant="outlined"
                fullWidth
                value={category}
                onChange={(e) => {
                    setCategory(e.target.value);
                }}
                label="Category"
                validators={['required']}
                errorMessages={['This field is required']}
                >
                <MenuItem value={0}>Others</MenuItem>
                {
                    categories.length > 0 && categories.map((e, i) => {
                        return <MenuItem value={e.id} key={i}>{e.title}</MenuItem>
                    })
                }
                </SelectValidator>
              }
              <TextValidator
              variant="outlined"
              value = {sort_order}
              type = "number"
              onChange={(e) => {setOrder(e.target.value);} }
              validators={['required']}
              errorMessages={['This field is required']}
              fullWidth
              label="Sort Order"
            />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Add</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>
            </Paper>
            </Grid>
          </Grid>
          <Footer />
        </Container>
      </main>
    </div>
  );
}
