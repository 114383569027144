import React from 'react';
import {Link} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import WithdrawalList from './list';
import DrawList from './list2';

export default function Draw() {
  return (
    <div className="root">
      <Header />
      <main className="content">
        <div className="verticle-spacer" />
        <Container maxWidth="lg" className="container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className="paper mb-2">
              <Box flexDirection="row" display="flex" justifyContent="space-between">
                <h1 className="page-title">Draws</h1>
                {/* <Link to="/prizes/add"><Button variant="contained" color="secondary">Add</Button></Link> */}
              </Box>
            </Paper>
            <Paper className="paper">
              <DrawList />  
            </Paper>
            </Grid>
          </Grid>
          <Footer />
        </Container>
      </main>
    </div>
  );
}
