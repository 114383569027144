import React, { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Alert from '../../components/Alert';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ImagePicker from '../../components/ImagePicker';
import FilterInput from '../../components/FilterInput';
import {updateFooterSettings, getOneFooterSettings} from '../../http/footersettings-requests';

import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

import {EditorState, convertToRaw, ContentState, convertFromHTML, createFromBlockArray} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import './style.scss';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

export default function EditFooterSettings(props) {
const id = 1;
const [tickets, setTickets] = useState(0);
const [generated, setGenerated] = useState([]);
const [won, setWon] = useState([]);
const [selected, setS] = useState([]);
const [d1, setD1] = useState("");
const [d2, setD2] = useState("");
const [d3, setD3] = useState("");
const [d4, setD4] = useState("");
const [d5, setD5] = useState("");
const [d6, setD6] = useState("");
const [d7, setD7] = useState("");
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState("");


useEffect(() => {
}, []);
const hashtagConfig = {
    trigger: '#',
    separator: ' ',
  }


const draw = (i) => {
  var t = [...selected];
    if(i == 7){
      var n = Math.ceil(Math.random() * (63 - 1) + 1);
    }else{
      var n = Math.ceil(Math.random() * (70 - 1) + 1);
    }
    var i2 = 0;
    while(t.includes(n) && i2<10){
      if(i == 7){
        var n = Math.ceil(Math.random() * (63 - 1) + 1);
      }else{
        var n = Math.ceil(Math.random() * (70 - 1) + 1);
      }
      console.log("going")
      i2++;
    }
    t.push(n);
    setS(t);
    calculate(i, t);
}

const redraw = () => {
  var t = [...generated];
  generated.map(async(n, i)=>{
      t[i][7] = [0,0,0,0,0,0,0];
      t[i][8] = 0;
      t[i][9] = '';
      t[i][11] = 0;
  });
  setGenerated(t);
  setS([]);
}

const rank =(match, sb) => {
  return new Promise((resolve, reject) => {
    try {
        var r = '';
        var r2 = 0;
        if(sb == 1){
          if(match == 1){
            r = 'X';
            r2=3;
          }
          else if(match == 2){
            r = '2X';
            r2=4;
          }
          else if(match == 3){
            r = '10X';
            r2=5;
            
          }
          else if(match == 4){
            r = '100X';
            r2=7;
          }
          else if(match == 5){
            r = '25000X';
            r2=8;
          }
          else if(match == 6){
            r = '100000X';
            r2=10;
          }
          else if(match == 7){
            r = '10 Cr';
            r2=12;
          }
        }else{
          if(match == 0){
            r = '';
            r2=0;
          }
          else if(match == 1){
            r = '';
            r2=1;
          }
          else if(match == 2){
            r = '';
            r2=2;
          }
          else if(match == 3){
            r = '2X';
            r2=4;
          }
          else if(match == 4){
            r = '50X';
            r2=6;
          }
          else if(match == 5){
            r = '30000X';
            r2=9;
          }
          else if(match == 6){
            r = '400000X';
            r2=11;
          }
        }
        resolve([r, r2]);

  console.log(r)
    } catch (error) {
      reject(['', 0])
    }
  })
}

const calculate = async (i3, t) =>{
  var i2 = i3-1;
  var r;
  var wo = [...generated];
  await wo.map(async(n, i)=>{
      if(n[i2] == t[i2]){
        wo[i][7][i2] = 1;
        wo[i][8] += 1;
        r = await rank(n[8], n[7][6]);
        wo[i][9] = r[0];
        wo[i][11] = r[1];
      }
  })
  process(wo);
}

const process = async (arr) =>{
  var arr2 = [...arr];
  await arr2.sort(function (a, b) {
      return b[11] - a[11];
  });
  // var arr2 = [...arr];
  // arr2.map(async(n, i)=>{
  //   arr2[i][9] = await rank(n[8], n[6]);
  // });
  setGenerated(arr2);
}


const submit = (e) => {
    e.preventDefault();
    var rows = [], ws=[];
    for(var i = 1; i<=tickets; i++){
      var arr = [];

      while(arr.length < 6){
        var n = Math.ceil(Math.random() * (70 - 1) + 1);
        if(!arr.includes(n)){
          arr.push(n);
        }
        if(arr.length == 6){
           while(arr.length < 7){
              var n = Math.ceil(Math.random() * (63 - 1) + 1);
              if(!arr.includes(n)){
                arr.push(n);
              }
            }
        }
      }
      arr.push([0,0,0,0,0,0,0]);
      arr.push(0);
      arr.push('');
      arr.push(i);
      arr.push(0);
      rows.push(arr);
      if(i == tickets){
        setGenerated(rows);
      }
    }
    
  };

return (
    <div className="addPage">
            {
                  generated.length == 0 && <ValidatorForm onSubmit={submit} >
               <h3>Generate Tickets</h3>
               <TextValidator
                    variant="outlined"
                    value = {tickets}
                    onChange={(e) => {setTickets(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="No. of Tickets"
                />
                
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Generate</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>}
                {
                  generated.length > 0 && <div className='result mt-3'>
                    
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Tkt #</TableCell>
                        <TableCell>N1</TableCell>
                        <TableCell>N2</TableCell>
                        <TableCell>N3</TableCell>
                        <TableCell>N4</TableCell>
                        <TableCell>N5</TableCell>
                        <TableCell>N6</TableCell>
                        <TableCell>SB</TableCell>
                        <TableCell>Match</TableCell>
                        <TableCell>Prize</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                     generated.map((el, i)=>{
                      return <TableRow key={i}>

                      <TableCell >{el[10]}</TableCell>
                      <TableCell className={el[7][0] == 1 ? 'sCell' : ''}>{el[0]}</TableCell>
                      <TableCell className={el[7][1] == 1 ? 'sCell' : ''}>{el[1]}</TableCell>
                      <TableCell className={el[7][2] == 1 ? 'sCell' : ''}>{el[2]}</TableCell>
                      <TableCell className={el[7][3] == 1 ? 'sCell' : ''}>{el[3]}</TableCell>
                      <TableCell className={el[7][4] == 1 ? 'sCell' : ''}>{el[4]}</TableCell>
                      <TableCell className={el[7][5] == 1 ? 'sCell' : ''}>{el[5]}</TableCell>
                      <TableCell className={el[7][6] == 1 ? 'sCell' : ''}>{el[6]}</TableCell>
                      <TableCell >{el[8]}</TableCell>
                      <TableCell >{el[9]}</TableCell>
                    </TableRow>
                    })
                  }
                    </TableBody>
                  </Table>
                  </Grid>
                  <Grid item xs={4}>
                    <div className='selected_row'>
                    {
                        selected.map((el, i)=>{
                          return <div className='sicon' key={i}>{el}</div>
                        })
                    }
                    </div>
                    {
                      selected.length == 0 && <Button
                      onClick={()=>draw(1)}
                      name="d1"
                      variant="contained"
                      color="primary"
                      className='drawbtn'
                      >Monday Draw</Button>
                    }
                    
                    {
                      selected.length == 1 && <Button
                      onClick={()=>draw(2)}
                      name="d2"
                      variant="contained"
                      color="primary"
                      className='drawbtn'
                      >Tuesday Draw</Button>
                    }
                    {
                      selected.length == 2 &&  <Button
                      onClick={(e)=>draw(3)}
                      name="d3"
                      variant="contained"
                      color="primary"
                      className='drawbtn'
                      >Wednessday Draw</Button>
                    }
                    {
                     selected.length == 3 && <Button
                      onClick={(e)=>draw(4)}
                      name="d4"
                      variant="contained"
                      color="primary"
                      className='drawbtn'
                      >Thursday Draw</Button>
                    }
                    {
                      selected.length == 4 && <Button
                      onClick={(e)=>draw(5)}
                      name="d5"
                      variant="contained"
                      color="primary"
                      className='drawbtn'
                      >Friday Draw</Button>
                    }
                    {
                      selected.length == 5 && <Button
                      onClick={(e)=>draw(6)}
                      name="d6"
                      variant="contained"
                      color="primary"
                      className='drawbtn'
                      >Saturday Draw</Button>
                    }
                    {
                      selected.length == 6 && <Button
                      onClick={(e)=>draw(7)}
                      name="d7"
                      variant="contained"
                      color="primary"
                      className='drawbtn'
                      >Sunday Draw</Button>
                    }
                   {
                      selected.length == 7 && <Button
                      name="d7"
                      variant="contained"
                      color="primary"
                      className='invbtn'
                      >Sunday Draw</Button>
                    }
                    <div className='selected_row'> 
                    
                    {
                      <Button
                      onClick={(e)=>window.location.reload()}
                      name="d7"
                      variant="contained"
                      color="secondary"
                      className='drawbtn'
                      >Go Back</Button>
                    }
                    &nbsp;&nbsp;
                    {
                      selected.length == 7 && <Button
                      onClick={(e)=>redraw()}
                      name="d7"
                      variant="contained"
                      color="primary"
                      className='drawbtn'
                      >Re-Draw</Button>
                    }
                    </div>
                  </Grid>
                </Grid>

                </div>
              }
               
                
        </div>
            
  );
}
