import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';

export const getAllCategories = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "categories", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getNestedCategories = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "categories-nested", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getOneCategory = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "categories/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const deleteCategory = (data) => {
    return new Promise((resolve, reject) => {
        httpDelete(config.apiBase + "categories/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updateCategory = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "categories/"+data.id, false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const addCategory = (data) => {
    return new Promise((resolve, reject) => {
        httpPost(config.apiBase + "categories", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const getTopCategories = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "categories-top", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};