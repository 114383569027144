import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';

export const getAllFrontPage = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "frontpage", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getNestedFrontPage = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "frontpage-nested", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getOneFrontPage = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "frontpage/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const deleteFrontPage = (data) => {
    return new Promise((resolve, reject) => {
        httpDelete(config.apiBase + "frontpage/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updateFrontPage = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "frontpage/"+data.id, false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const addFrontPage = (data) => {
    return new Promise((resolve, reject) => {
        httpPost(config.apiBase + "frontpage", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const getTopFrontPage = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "frontpage-top", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};