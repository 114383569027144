import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import config from '../../config';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import {getAllAgents, deleteAgent} from '../../http/agent-requests';
import './style.scss';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

function preventDefault(event) {
  event.preventDefault();
}


export default function AgentList() {

  const [rows, setRows] = useState([]);
  useEffect(() => {
      getRows();
  }, []);

  const getRows = () => {
    getAllAgents().then(resp => {
      console.log(resp);
      setRows(resp);
    }, err => {
      console.log(err);
    })
  }

  const deleteRow = (id) => {
    deleteAgent(id).then(resp => {
      console.log(resp);
      getRows();
    }, err => {
      console.log(err);
    })
  }

  return (
    <div className="agent-list">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <React.Fragment>
            <TableRow key={row.id} className="level1">
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell><Moment date={row.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell>{row.updated_at != null && <Moment date={row.updated_at} format="DD-MM-YY hh:mm A" />}</TableCell>
              <TableCell>
                <IconButton  className="text-danger" onClick={(e) => deleteRow(row.id)}>
                  <DeleteIcon />
                </IconButton>
                <Link href={`/agents/edit/${row.id}`}><IconButton  className="text-info">
                  <EditIcon />
                </IconButton></Link>
              </TableCell>
            </TableRow>
      
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
