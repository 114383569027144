import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';

export const addAgent = (data) => {
    return new Promise((resolve, reject) => {
        httpPost(config.apiBase + "Agents", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getAllAgents = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "Agents", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const deleteAgent = (data) => {
    return new Promise((resolve, reject) => {
        httpDelete(config.apiBase + "Agents/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getOneAgent = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "Agents/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updateAgent = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "Agents/"+data.id, false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};