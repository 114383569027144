import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';

export const getAllPages = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "pages", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getNestedPages = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "pages-nested", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getOnePage = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "pages/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const deletePage = (data) => {
    return new Promise((resolve, reject) => {
        httpDelete(config.apiBase + "pages/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updatePage = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "pages/"+data.id, false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const addPage = (data) => {
    return new Promise((resolve, reject) => {
        httpPost(config.apiBase + "pages", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const getTopPages = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "pages-top", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};