import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import config from '../../config';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import {getAllPrizes, deletePrize} from '../../http/prize-requests';
import './style.scss';
import { getLastDraw, viewDraw } from '../../http/extra-requests';
import Popup from '../../components/Popup';
import { Button } from '@material-ui/core';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

function preventDefault(event) {
  event.preventDefault();
}


export default function DrawList() {
    const [row, setRow] = useState(null);
    const [popup, setPopup] = React.useState(false);

  const [rows, setRows] = useState([]);
  useEffect(() => {
      getRows();
  }, []);

  const getRows = () => {
    getLastDraw().then(resp => {
      console.log(resp);
      setRows(resp);
    }, err => {
      console.log(err);
    })
  }

  const viewRow = (id) => {
    viewDraw({id}).then(resp => {
        setRow(resp);
        setPopup(true);
    }, err => {
      console.log(err);
    })
  }

  return (
    <div className="prize-list">
    <Popup open={popup} setOpen={(v) => setPopup(v)} heading="Winners List" >
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Ticket #</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>User</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {
                
                row && row.map((r) => (
                    <React.Fragment>
                    <TableRow key={r.id} className="level1">
                        <TableCell>#{r.ticket}</TableCell>
                        <TableCell>{r.amount}</TableCell>
                        <TableCell><div>{r.username}</div><div className='text-primary'>{r.userphone}</div></TableCell>
                    </TableRow>
            
                    </React.Fragment>
                ))
            }
            </TableBody>
        </Table>
    </Popup>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Created At</TableCell>
            <TableCell>Winning Tickets</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <React.Fragment>
            <TableRow key={row.id} className="level1">
              <TableCell><Moment date={row.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell>
                <Button variant="contained" color="secondary" onClick={(e) => viewRow(row.id)}>
                  View
                </Button>
                </TableCell>
                {/* <TableCell>
                <Button variant="contained" color="secondary" onClick={(e) => viewRow(row.id)}>
                  View
                </Button>
                </TableCell> */}
            </TableRow>
      
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
