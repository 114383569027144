import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Alert from '../../components/Alert';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ImagePicker from '../../components/ImagePicker';
import {addBrand} from '../../http/brand-requests';

import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function AddBrand() {
const [title, setTitle] = useState("");
const [slug, setSlug] = useState("");
const [logo, setLogo] = useState("");
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState("");

const slugify = (txt) => {
    txt = txt.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
    setSlug(txt);
};

const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    var data = {
        title,
        slug,
        logo
    };
    addBrand(data).then(resp => {
        if (resp != '') {
            window.location.href = "/brands";
        }
      }, error => {
        console.log(error);
        setAlert(error.data.message);
        setLoading(false);
      });
  };

return (
    <div className="root">
      <Header />
      <main className="content">
        <Alert alert={alert} setAlert={setAlert} />
        <div className="verticle-spacer" />
        <Container maxWidth="lg" className="container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className="paper mb-2">
              <Box flexDirection="row" display="flex" justifyContent="space-between">
                <h1 className="page-title">Add Brand</h1>
                <Link to="/brands"><Button variant="contained" color="secondary">Back</Button></Link>
              </Box>
            </Paper>
            <Paper className="paper">
            <ValidatorForm onSubmit={submit}>
                <TextValidator
                    variant="outlined"
                    value = {title}
                    onChange={(e) => {setTitle(e.target.value); slugify(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    id="email"
                    label="Brand Title"
                    name="title"
                    autoComplete="title"
                    autoFocus
                />
                <TextValidator
                    variant="outlined"
                    fullWidth
                    name="password"
                    value = {slug}
                    onChange={(e) => setSlug(e.target.value)}
                    label="URL Slug"
                    type="text"
                    id="slug"
                    validators={['required']}
                    errorMessages={['This field is required']}
                    autoComplete="slug"
                />

                <div className="mb-1 sub-title">Logo:</div>
                <ImagePicker limit={1} id="brand-image" picked={setLogo} />
              
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Add</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>
            </Paper>
            </Grid>
          </Grid>
          <Footer />
        </Container>
      </main>
    </div>
  );
}
