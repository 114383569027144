import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import {getAllFrontPage, deleteFrontPage} from '../../http/frontpage-requests';
import './style.scss';

export default function FrontBlocksList() {

  const [rows, setRows] = useState([]);
  useEffect(() => {
      getRows();
  }, []);

  const getRows = () => {
    getAllFrontPage().then(resp => {
      console.log(resp);
      setRows(resp);
    }, err => {
      console.log(err);
    })
  }

  const deleteRow = (id) => {
    deleteFrontPage(id).then(resp => {
      console.log(resp);
      getRows();
    }, err => {
      console.log(err);
    })
  }

  const getType = (key) => {
    switch (key) {
      case 1:
        return 'Slider Block';
        break;
      case 2:
        return 'Three Ads Block';
        break;
      case 3:
        return 'Category Wise Carousel';
        break;
      case 4:
        return 'Recent Products Carousel';
        break;
      case 5:
        return 'Featured Products Carousel';
        break;
      case 6:
      return 'Offers Block';
      break;
      case 7:
      return 'Single Ad Block';
      break;
      case 8:
      return 'Videos Block';
      break;
      default:
        return '';
        break;
    }
  };

  return (
    <div className="frontpage-list">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Sort Order</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} className="level1">
              <TableCell>{row.title}<div className="text-secondary text-small">{row.sub_title}</div></TableCell>
              <TableCell>{
                getType(row.block_type)
              }</TableCell>
              <TableCell>{row.sort_order}</TableCell>
              <TableCell><Moment date={row.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell><Moment date={row.updated_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell>
                <IconButton aria-label="show 4 new mails" className="text-danger" onClick={(e) => deleteRow(row.id)}>
                  <DeleteIcon />
                </IconButton>
                <Link href={`/settings/frontpage/edit/${row.id}`}><IconButton aria-label="show 4 new mails" className="text-info">
                  <EditIcon />
                </IconButton></Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
