import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../assets/images/logo.png';
import {login} from '../../http/user-requests';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.scss';
import config from '../../config';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={config.home}>
      {config.name}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const classes = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
class Login extends React.Component {
  state = {
    alert: '',
    loading: false,
    username: '',
    password: ''
  }
  handleChange = (event) => {
    console.log(event.target)
    this.setState({ [event.target.name] : event.target.value });
}

handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  this.setState({
    alert : ''
  })
};

  login = (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    }, () => {
      login(this.state).then(resp => {
        console.log(resp);
        if (resp != '') {
            localStorage.setItem('admin_auth', resp)
            window.location.href = "/";
        }
      }, error => {
        console.log(error);
        this.setState({
          alert: error.data.message,
          loading: false
        })
      });
    })
  }
  render() {
    return (
      <div className="loginPage">
      <Container component="main" maxWidth="xs" className="loginDiv">
      <CssBaseline />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={(this.state.alert == '') ? false : true}
        autoHideDuration={3000}
        onClose={this.handleClose}
        message={this.state.alert}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <div className={classes.paper}>
          <img src={"/logo.png"} alt="Logo" className="logo" />
          <h3 className="text-center">Admin Panel</h3>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <ValidatorForm className={classes.form} onSubmit={this.login}>
          <TextValidator
            variant="outlined"
            margin="normal"
            value = {this.state.username}
            onChange={this.handleChange}
            validators={['required']}
            errorMessages={['This field is required']}
            fullWidth
            id="email"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextValidator
            variant="outlined"
            margin="normal"
            
            fullWidth
            name="password"
            value = {this.state.password}
            onChange={this.handleChange}
            label="Password"
            type="password"
            id="password"
            validators={['required']}
            errorMessages={['This field is required']}
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={this.state.loading}
          >
            { !this.state.loading && <span>Login</span> }
            { this.state.loading && <CircularProgress color="secondary" /> }
          </Button>
        </ValidatorForm>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </div>
    );
    }
}

export default Login;