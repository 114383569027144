import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function Alert(props) {

const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    props.setAlert('');
};

return (
    <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={(props.alert == '') ? false : true}
        autoHideDuration={3000}
        onClose={handleClose}
        message={props.alert}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
  );
}
