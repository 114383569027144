import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
import Alert from '../../components/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import config from '../../config';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PrintIcon from '@material-ui/icons/Print';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import {getAllOrders, shiprocketAWB, shiprocketCancel, shiprocketPicup, shiprocketPrint, updateOrderStatus} from '../../http/order-requests';
import './style.scss';
import { Button, Modal } from '@material-ui/core';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

function preventDefault(event) {
  event.preventDefault();
}


export default function BrandList() {

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [length, setLength] = useState('');
  const [breadth, setBreadth] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [id, setId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [alert, setAlert] = useState('');
  const [showModal, setModal] = useState(false);
  const [showModal2, setModal2] = useState(false);
  
  useEffect(() => {
      getRows();
  }, []);

  const modal = (id = null, oid = null) => {
    setId(id);
    setOrderId(oid);
    setModal(!showModal);
  }

  const cancelModal = (id = null, oid = null) => {
    setId(id);
    setOrderId(oid);
    setModal2(!showModal2);
  }

  const getRows = () => {
    getAllOrders().then(resp => {
      console.log(resp);
      setRows(resp);
    }, err => {
      
    })
  }

  const updateStatus = (id, val) =>{
    console.log(id, val);
    updateOrderStatus({id, status: val}).then(resp => {
      getRows();
    }, err => {
      console.log(err);
      // setAlert(err.data.message);
    })
  }

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    shiprocketPicup({id, orderId, weight, height, breadth, length}).then(resp => {
      setLoading(false);
      setModal(false);
      getRows();
    }, err => {
      setAlert(err.data.message);
      setModal(false);
      setLoading(false);
    })
  }

  const submit2 = (e) => {
    e.preventDefault();
    setLoading(true);
    shiprocketCancel({id, orderId}).then(resp => {
      setLoading(false);
      setModal2(false);
      getRows();
    }, err => {
      setAlert(err.data.message);
      setModal2(false);
      setLoading(false);
    })
  }

  const submit3 = (id, orderId) => {
    setLoading(true);
    shiprocketAWB({id, orderId}).then(resp => {
      setLoading(false);
      getRows();
    }, err => {
      setAlert(err.data.message);
      setLoading(false);
    })
  }

  const submit4 = (id) => {
    setLoading(true);
    shiprocketPrint({id}).then(resp => {
      setLoading(false);
      window.open(resp.url);
    }, err => {
      setAlert(err.data.message);
      setLoading(false);
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert('');
  };


  return (
    <div className="brand-list">
            <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={(alert == '') ? false : true}
        autoHideDuration={3000}
        onClose={handleClose}
        message={alert}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Modal open={showModal} className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">
              Create Shiprocket Pickup Request
            </h3>
           <CloseIcon className="modal-close" onClick={modal} />
          </div>
          <div className="modal-body">
          <ValidatorForm  onSubmit={submit}>
          <TextValidator
            variant="outlined"
            margin="normal"
            value = {length}
            onChange={(e) => setLength(e.target.value)}
            validators={['required']}
            errorMessages={['This field is required']}
            fullWidth
            label="Length"
            name="length"
            autoComplete="length"
            autoFocus
          />
           <TextValidator
            variant="outlined"
            margin="normal"
            value = {breadth}
            onChange={(e) => setBreadth(e.target.value)}
            validators={['required']}
            errorMessages={['This field is required']}
            fullWidth
            label="Breadth"
            name="breadth"
            autoComplete="breadth"
          />
           <TextValidator
            variant="outlined"
            margin="normal"
            value = {height}
            onChange={(e) => setHeight(e.target.value)}
            validators={['required']}
            errorMessages={['This field is required']}
            fullWidth
            label="Height"
            name="height"
            autoComplete="height"
          />
           <TextValidator
            variant="outlined"
            margin="normal"
            value = {weight}
            onChange={(e) => setWeight(e.target.value)}
            validators={['required']}
            errorMessages={['This field is required']}
            fullWidth
            label="Weight"
            name="weight"
            autoComplete="weight"
          />
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
          >
            { !loading && <span>Submit Request</span> }
            { loading && <CircularProgress color="secondary" /> }
          </Button>
        </ValidatorForm>
          </div>
        </div>
      </Modal>

      <Modal open={showModal2} className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">
              Confirm
            </h3>
           <CloseIcon className="modal-close" onClick={cancelModal} />
          </div>
          <div className="modal-body">
              <h3>Are you sure about cancelling this order?</h3>
              <p className="text-danger">you have to manually refund the user via Razorpay once the order is cancelled.</p>
              
              <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={submit2}
          >
            { !loading && <span>Yes, Go Ahead</span> }
            { loading && <CircularProgress color="secondary" /> }
          </Button>&nbsp; &nbsp;
              <Button variant="contained" color="secondary" onClick={cancelModal}>No, Go Back</Button>
          </div>
        </div>
      </Modal>

      <Alert alert={alert} setAlert={setAlert} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 && rows.map((r, i) => {
              return <TableRow key={i}>
              <TableCell><div>Order ID: {r.order_id}</div><div>Item ID : {r.item_id}</div></TableCell>
              <TableCell>{r.title}</TableCell>
              <TableCell>{r.qty}</TableCell>
              <TableCell>₹ {r.total}</TableCell>
              <TableCell><Moment date={r.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell>
                {
                  r.updated_at != null && <Moment date={r.updated_at} format="DD-MM-YY hh:mm A" />
                }
              </TableCell>
              <TableCell>
                {
                  r.status == 0 ?
                  <span className="text-primary">Placed</span>
                  :
                  r.status == 1 ?
                  r.sr_awb_code == null ?
                  <span className="text-secondary">AWB Request Failed</span>
                  :
                  <span className="text-secondary">Pickup Requested</span>
                  :
                  r.status == 2 ? 
                  <span className="text-success">Delivered</span>
                  :
                  <span className="text-danger">Cancelled</span>
                }
              </TableCell>
              <TableCell>
              <Link to={"/orders/invoice/"+r.item_id} target="_BLANK"><IconButton aria-label="show 4 new mails" className="text-primary">
                  <VisibilityIcon />
             </IconButton></Link>
             {
                r.status == 1 && r.sr_awb_code != null && <IconButton aria-label="show 4 new mails" className="text-primary"  onClick={()=>submit4(r.id)}>
                  { !loading && <PrintIcon /> }
                  { loading && <CircularProgress color="secondary" /> }
             </IconButton>}
             {
                r.status == 0 &&  <Button color="secondary" variant="contained" onClick={()=>modal(r.id, r.order_id)}>Pickup</Button>
            }
            {
                r.status == 1 && r.sr_awb_code == null && <Button color="secondary" variant="contained" onClick={()=>submit3(r.id, r.order_id)} disabled={loading} >
                  { !loading && <span>Generate AWB</span> }
                  { loading && <CircularProgress color="secondary" /> }
                </Button>
            }
              {
                r.status == 1 && r.sr_awb_code != null && <Button color="dark" variant="contained" onClick={()=>cancelModal(r.id, r.order_id)}>Cancel</Button>
            }
                {/* <select onChange={(e) => updateStatus(r.order_id, e.target.value)} value={r.status}>
                  <option value="0">Placed</option>
                  <option value="1">Dispatched</option>
                  <option value="2">Delivered</option>
                  <option value="-1">Cancelled</option>
                </select> */}
              </TableCell>
            </TableRow>
            })
          }
        </TableBody>
      </Table>
    </div>
  );
}
