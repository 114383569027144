import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import TicketIcon from "@material-ui/icons/Bookmark";
import CategoryIcon from "@material-ui/icons/Category";
import GoalIcon from "@material-ui/icons/CenterFocusStrong";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Visibility from "@material-ui/icons/DragHandle";
import LayersIcon from "@material-ui/icons/Layers";
import TransIcon from "@material-ui/icons/List";
import MailIcon from "@material-ui/icons/Mail";
import {
  default as BankIcon,
  default as PrizeIcon,
} from "@material-ui/icons/Money";
import PeopleIcon from "@material-ui/icons/People";
import StarIcon from "@material-ui/icons/Star";
import VerifyIcon from "@material-ui/icons/VerifiedUser";
import React from "react";
import { Link } from "react-router-dom";

export const Sidebar = (
  <div>
    <Link to="/">
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </Link>
    <Link to="/users">
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItem>
    </Link>
    <Link to="/kyc/all">
      <ListItem button>
        <ListItemIcon>
          <VerifyIcon />
        </ListItemIcon>
        <ListItemText primary="KYC" />
      </ListItem>
    </Link>
    <Link to="/transactions/all">
      <ListItem button>
        <ListItemIcon>
          <TransIcon />
        </ListItemIcon>
        <ListItemText primary="Transactions" />
      </ListItem>
    </Link>
    <Link to="/tickets/all">
      <ListItem button>
        <ListItemIcon>
          <TicketIcon />
        </ListItemIcon>
        <ListItemText primary="Tickets" />
      </ListItem>
    </Link>

    <Link to="/goals/all">
      <ListItem button>
        <ListItemIcon>
          <GoalIcon />
        </ListItemIcon>
        <ListItemText primary="Goals" />
      </ListItem>
    </Link>

    <Link to="/banks/all">
      <ListItem button>
        <ListItemIcon>
          <BankIcon />
        </ListItemIcon>
        <ListItemText primary="Banks" />
      </ListItem>
    </Link>

    <Link to="/withdrawals/all">
      <ListItem button>
        <ListItemIcon>
          <Visibility />
        </ListItemIcon>
        <ListItemText primary="Withdrawals" />
      </ListItem>
    </Link>
    {/* 
    <Link to="/agents">
    <ListItem button>
      <ListItemIcon>
        <LocalShipping />
      </ListItemIcon>
      <ListItemText primary="Delivery Agents" />
    </ListItem>
    </Link>
    <Link to="/categories">
      <ListItem button>
      <ListItemIcon>
        <LocalOfferIcon />
      </ListItemIcon>
      <ListItemText primary="Categories" />
    </ListItem>
    </Link> */}
    {/* <Link to="/brands">
      <ListItem button>
      <ListItemIcon>
        <FilterVintageIcon />
      </ListItemIcon>
      <ListItemText primary="Brands" />
    </ListItem>
    </Link> */}
    {/* <Link to="/products">
      <ListItem button>
      <ListItemIcon>
        <CategoryIcon />
      </ListItemIcon>
      <ListItemText primary="Products" />
       </ListItem>
    </Link> */}
    {/* <Link to="/orders">
      <ListItem button>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItem>
    </Link> */}
    {/* <Link to="/ratings">
      <ListItem button>
        <ListItemIcon>
          <StarIcon />
        </ListItemIcon>
        <ListItemText primary="Ratings" />
      </ListItem>
    </Link> */}
    <Link to="/pages">
      <ListItem button>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Static Pages" />
      </ListItem>
    </Link>

    <Link to="/prizes">
      <ListItem button>
        <ListItemIcon>
          <PrizeIcon />
        </ListItemIcon>
        <ListItemText primary="Prizes" />
      </ListItem>
    </Link>
    {/* <Link to="/expenses">
    <ListItem button>
      <ListItemIcon>
        <PaymentIcon />
      </ListItemIcon>
      <ListItemText primary="Expenses" />
    </ListItem>
    </Link> */}
    <Link to="/draws">
      <ListItem button>
        <ListItemIcon>
          <StarIcon />
        </ListItemIcon>
        <ListItemText primary="Draws" />
      </ListItem>
    </Link>

    <Link to="/simulator">
      <ListItem button>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Simulator" />
      </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Settings</ListSubheader>
    <Link to="/settings">
      <ListItem button>
        <ListItemIcon>
          <MailIcon />
        </ListItemIcon>
        <ListItemText primary="General" />
      </ListItem>
    </Link>
    {/* <Link to="/settings/delivery">
    <ListItem button>
      <ListItemIcon>
        <LocalShipping />
      </ListItemIcon>
      <ListItemText primary="Delivery" />
    </ListItem>
    </Link> */}
    {/* <Link to="/settings/frontpage">
    <ListItem button>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Front Page" />
    </ListItem>
    </Link>
    <Link to="/settings/payment">
    <ListItem button>
      <ListItemIcon>
        <PaymentIcon />
      </ListItemIcon>
      <ListItemText primary="Payment" />
    </ListItem>
    </Link>
    <Link to="/settings/header">
    <ListItem button>
      <ListItemIcon>
        <MenuIcon />
      </ListItemIcon>
      <ListItemText primary="Header" />
    </ListItem>
    </Link>
    <Link to="/settings/footer">
    <ListItem button>
      <ListItemIcon>
        <CopyrightIcon />
      </ListItemIcon>
      <ListItemText primary="Footer" />
    </ListItem>
    </Link> */}
    {/* <Link to="/settings/other">
    <ListItem button>
      <ListItemIcon>
        <OtherIcon />
      </ListItemIcon>
      <ListItemText primary="Other Settings" />
    </ListItem>
    </Link> */}
  </div>
);
