import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {getAllUsers, deleteUser, updateUser} from '../../http/user-requests';
import GoalIcon from '@material-ui/icons/CenterFocusStrong';
import BankIcon from '@material-ui/icons/Money';
import KycIcon from '@material-ui/icons/VerifiedUser';
import TransIcon from '@material-ui/icons/List';
import TicketIcon from '@material-ui/icons/Bookmark';
import Visibility from '@material-ui/icons/Visibility';
import RowPopup from '../../components/Popup/row';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}



function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function UserOrders() {
  const classes = useStyles();
  const [row, setRow] = useState(null);
  const [popup, setPopup] = React.useState(false);
  const [rows, setRows] = useState([]);
  useEffect(() => {
      getRows();
  }, []);

  const getRows = () => {
    getAllUsers().then(resp => {
      console.log(resp);
      setRows(resp);
    }, err => {
      console.log(err);
    })
  }

  const deleteRow = (id) => {
    deleteUser(id).then(resp => {
      console.log(resp);
      getRows();
    }, err => {
      console.log(err);
    })
  }

  const viewRow = (row) => {
    setRow(row);
    setPopup(true);
  }

  const activateRow = (id) => {
    updateUser({id: id, status: 1}).then(resp => {
      console.log(resp);
      getRows();
    }, err => {
      console.log(err);
    })
  }

  const deactivateRow = (id) => {
    updateUser({id: id, status: 0}).then(resp => {
      console.log(resp);
      getRows();
    }, err => {
      console.log(err);
    })
  }

  return (
    <React.Fragment>
      <RowPopup open={popup} setOpen={(v) => setPopup(v)} heading="Details" row={row} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>OTP</TableCell>
            <TableCell>Savings</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Links</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell><div className=''>{row.name}</div>
                <div className='text-success'>{row.referral_code}</div></TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell>{row.mobile_otp}</TableCell>
              <TableCell >
                <div className='text-danger'>{row.total_tickets} Tkts</div>
                {row.bonus_tickets > 0 && <div className='text-primary'>{row.bonus_tickets} Bonus</div> }
                <div className='text-success'>₹{row.total_savings}</div>
              </TableCell>
              <TableCell><Moment date={row.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell>{
                row.status == 1 ?
                <span className="text-primary" variant="contained" color="primary">Active</span>
                :
                <span className="text-danger" variant="contained" color="secondary">In-active</span>
                }
              </TableCell>
              <TableCell>
                <IconButton color="primary" onClick={(e) => window.open(`/kyc/${row.id}`)} title="KYC">
                  <KycIcon />
                </IconButton>
                <IconButton color="primary" onClick={(e) => window.open(`/transactions/${row.id}`)} title="Transactions">
                  <TransIcon />
                </IconButton>
                <IconButton color="primary" onClick={(e) => window.open(`/tickets/${row.id}`)} title="Tickets">
                  <TicketIcon />
                </IconButton>
                <IconButton color="primary" onClick={(e) => window.open(`/goals/${row.id}`)} title="Goals">
                  <GoalIcon />
                </IconButton>
                <IconButton color="primary" onClick={(e) => window.open(`/banks/${row.id}`)} title="Banks">
                  <BankIcon />
                </IconButton>

                </TableCell>
              <TableCell>
                <IconButton color="secondary" onClick={(e) => viewRow(row)}>
                  <Visibility />
                </IconButton>

                <IconButton color="secondary" onClick={(e) => deleteRow(row.id)}>
                  <DeleteIcon />
                </IconButton>

                {
                row.status == 1 ?
                <IconButton color="secondary" onClick={(e) => deactivateRow(row.id)}>
                <BlockIcon />
                </IconButton>
                :
                <IconButton color="primary" onClick={(e) => activateRow(row.id)}>
                <CheckCircleOutlineIcon />
                </IconButton>
                }
              {/* <Button variant="contained" color="secondary" className="loginBtn">
                    Login
              </Button> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        {/* <Link color="primary" href="#" onClick={preventDefault}>
          See more orders
        </Link> */}
      </div>
    </React.Fragment>
  );
}
