import React, { useEffect, useState } from 'react';
import {upload} from  '../../http/media-requests';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import config from '../../config';
import './style.scss';

export default function FilterInput(props) {

    const [picked, setPicked] = useState([]);
    useEffect(()=>{
        if(props.selected != null){
            setPicked(props.selected);
        }
    }, [props.selected]);

    const addNew = () => {
        setPicked([...picked, {}]);
    };

    const handleChange = (e, i) => {
        var temp = [...picked];
        var val = e.target.value;
        var name = e.target.name;
        temp[i][name] = val;
        setPicked(temp);
        props.picked(temp);
    };

    const remove = (e) => {
        var temp = [...picked];
        temp.splice(e, 1);
        setPicked(temp);
        props.picked(temp);
    };
    
    return (
    <div className="filter-input">
        {
            picked.map((el, i) => {
                return <div className="item" key={i}>
                    <CloseIcon className="close" onClick={() => remove(i)} />
                    <input type="text" onChange={(e) => handleChange(e, i)} placeholder={props.keyLabel != null ? props.keyLabel : "Key"} name={props.tag+"_key"} value={el[props.tag+"_key"]} />
                    <input type="text" onChange={(e) => handleChange(e, i)} placeholder={props.valueLabel != null ? props.valueLabel : "Value"} name={props.tag+"_value"} value={el[props.tag+"_value"]} />
                </div>
            })
        }
        {
            picked.length < props.limit && <div className="item add" onClick={addNew}>
                <AddIcon />
            </div>
        }
    </div>
    );
    
}