import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Alert from '../../components/Alert';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ImagePicker from '../../components/ImagePicker';
import {addAgent} from '../../http/agent-requests';

import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function AddAgent() {
const [name, setName] = useState("");
const [phone, setPhone] = useState("");
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState("");

const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    var data = {
        name,
        phone,
        email,
        password
    };
    addAgent(data).then(resp => {
        if (resp != '') {
            window.location.href = "/agents";
        }
      }, error => {
        console.log(error);
        setAlert(error.data.message);
        setLoading(false);
      });
  };

return (
    <div className="root">
      <Header />
      <main className="content">
        <Alert alert={alert} setAlert={setAlert} />
        <div className="verticle-spacer" />
        <Container maxWidth="lg" className="container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className="paper mb-2">
              <Box flexDirection="row" display="flex" justifyContent="space-between">
                <h1 className="page-title">Add Agent</h1>
                <Link to="/agents"><Button variant="contained" color="secondary">Back</Button></Link>
              </Box>
            </Paper>
            <Paper className="paper">
            <ValidatorForm onSubmit={submit}>
           

                
                <TextValidator
                    variant="outlined"
                    value = {name}
                    onChange={(e) => {setName(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Agent Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                />
                <TextValidator
                    variant="outlined"
                    value = {phone}
                    onChange={(e) => {setPhone(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Phone"
                    name="phone"
                    autoComplete="phone"
                    autoFocus
                />
                <TextValidator
                    variant="outlined"
                    value = {email}
                    onChange={(e) => {setEmail(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                
                <TextValidator
                    variant="outlined"
                    value = {password}
                    onChange={(e) => {setPassword(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    type="password"
                    label="Password"
                    name="password"
                    autoComplete="password"
                    autoFocus
                />
                
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Add</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>
            </Paper>
            </Grid>
          </Grid>
          <Footer />
        </Container>
      </main>
    </div>
  );
}
