import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Visibility from '@material-ui/icons/Visibility';
import VerifiedUser from '@material-ui/icons/Check';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import RowPopup from '../../components/Popup/row';
import Popup from '../../components/Popup/index';
import { getAllWithdrawals } from '../../http/extra-requests';

import './style.scss';
import { Button, CircularProgress, MenuItem } from '@material-ui/core';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { updateWithdrawStatus } from '../../http/admin-requests';



export default function WithdrawalList() {
  const { id } = useParams();
  const [rows, setRows] = useState([]);
  const [row, setRow] = useState(null);
  const [i1, setIn] = useState(0);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [popup, setPopup] = React.useState(false);
  const [popup2, setPopup2] = React.useState(false);
  
  useEffect(() => {
      getRows();
  }, []);

  const getRows = () => {
    getAllWithdrawals({id}).then(resp => {
      console.log(resp);
      setRows(resp);
    }, err => {
      console.log(err);
    })
  }

  const viewRow = (row) => {
    setRow(row);
    setPopup(true);
  }

  const viewStatus = (i, row) => {
    setIn(i);
    setRow(row);
    setStatus(row.status);
    setPopup2(true);
  }

  const submit = (e) => {
   e.preventDefault();
   var data={
    id: row.id,
    status: status
   }
   updateWithdrawStatus(data).then(res=>{
    let temp = [...rows];
    temp[i1]['status'] =  status;
    setPopup2(false)
   }, err=>{
    setPopup2(false)
   })
   
  }

  return (
    <div className="product-list">
      <Popup open={popup2} setOpen={(v) => setPopup2(v)} heading="Status Update" >
      <ValidatorForm onSubmit={submit} className="mt-2">

                <SelectValidator
                    variant="outlined"
                    fullWidth
                    value={status}
                    onChange={(e) => {
                        setStatus(e.target.value);
                    }}
                    label="Status"
                    validators={['required']}
                    errorMessages={['This field is required']}
                    >
                    <MenuItem value={0}>Pending</MenuItem>
                    <MenuItem value={1}>Completed</MenuItem>
                    <MenuItem value={-1}>Rejected</MenuItem>
                </SelectValidator>
                
                
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Update</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
          </ValidatorForm>
      </Popup>
      <RowPopup open={popup} setOpen={(v) => setPopup(v)} heading="Details" row={row} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Bank</TableCell>
            <TableCell>Withdraw Amount</TableCell>
            <TableCell>Mode</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={row.id} className="level1">
              <TableCell>{row.username}<br/><span className='text-primary'>{row.userphone}</span></TableCell>
              <TableCell>
                <div className='text-primary'>{row.bank_name}</div>
                <div>{row.acc_no}</div>
                <div>{row.ifsc}</div>
              </TableCell>
              <TableCell>₹{row.amount}</TableCell>
              <TableCell>{row.mode}</TableCell>
              <TableCell>
                {row.status == 1 && <div className='text-success'>Completed</div>}
                {row.status == -1 && <div className='text-danger'>Rejected</div>}
                {row.status != 1 && row.status != -1 && <div className='text-primary'>Pending</div>}
              </TableCell>
              <TableCell><Moment date={row.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell>{row.last_updated_at != null && <Moment date={row.last_updated_at} format="DD-MM-YY hh:mm A" />}</TableCell>
              <TableCell>
                <IconButton color="secondary" onClick={(e) => viewRow(row)}>
                  <Visibility />
                </IconButton>
                { row.status != 1 && row.status != -1 && <IconButton color="primary" onClick={(e) => viewStatus(i, row)}>
                  <VerifiedUser />
                </IconButton> }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
