import React, {useState, useEffect} from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import Title from './Title';
import {getAllOrders, updateOrderStatus} from '../../http/order-requests';
import Moment from 'react-moment';
// Generate Sales Data
function createData(time, amount) {
  return { time, amount };
}

const data = [
  createData('00:00', 0),
  createData('03:00', 300),
  createData('06:00', 600),
  createData('09:00', 800),
  createData('12:00', 1500),
  createData('15:00', 2000),
  createData('18:00', 2400),
  createData('21:00', 2400),
  createData('24:00', undefined),
];

export default function Chart() {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = () => {
    var date = new Date().toJSON().slice(0, 10);
    getAllOrders({createdAt: date}).then(resp => {
      let data = [];
      try {
        if(resp != null && resp != undefined && resp.length > 0){
          resp.forEach(element => {
            var temp = element.created_at.split("T");
            temp = temp[1].split(".000");
            temp = temp[0].split(":");
            data.push(createData(temp[0]+":"+temp[1], element.total));
          });
        }
      } catch (error) {
        
      }
      
      setRows(data);
    }, err => {
      console.log(err);
    })
  };
  return (
    <React.Fragment>
      <Title>Today Savings</Title>
      <ResponsiveContainer>
        <LineChart
          data={rows}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              Savings (₹)
            </Label>
          </YAxis>
          <Line type="monotone" dataKey="amount" stroke={theme.palette.primary.main} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
