import React, { useEffect, useState } from 'react';
import {upload} from  '../../http/media-requests';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import config from '../../config';
import './style.scss';

export default function ImagePicker(props) {
    const [picked, setPicked] = useState([]);
    useEffect(()=>{
        if(props.selected != null){
            console.log(props.selected);
            setPicked(props.selected);
        }
    }, [props.selected]);
    const addNew = () => {
        var input = document.getElementById(props.id);
        input.click();
    };

    const pick = (e) => {
        upload({file: e.target.files[0]}).then(resp => {
            var temp = [...picked, resp];
            setPicked(temp);
            props.picked(temp);
            e.target.value = '';
        }, error => {
            console.log(error);
        });
    };

    const remove = (e) => {
        var temp = [...picked];
        temp.splice(e, 1);
        setPicked(temp);
        props.picked(temp);
    };
    
    return (
    <div className="image-picker">
        <input type="file" className="hide picker-input" id={props.id} accept="image/*" onChange={pick} />
        {
            picked.map((e, i) => {
                return <div className={"item "+(props.size != null ? props.size : '')} key={i} style={{backgroundImage: `url(${config.staticBase+e.thumbnail})`}}>
                    <CloseIcon className="close" onClick={() => remove(i)} />
                </div>
            })
        }
        {
            picked.length < props.limit && <div className="item add" onClick={addNew}>
                <AddIcon />
            </div>
        }
    </div>
    );
    
}