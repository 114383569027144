import React from 'react';
import {getOneOrder} from '../../http/order-requests';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import './style.scss';
// Create styles
const styles = StyleSheet.create({
  page: {
      backgroundColor: "#ffffff"
  },
  section: {
      margin: 10,
      padding: 10
  },
  title: {
    fontSize: 28,
    margin:10,
    textAlign: 'center'
  },
  centerP: {
    fontSize: 14,
    textAlign: 'center'
  },
  stitle: {
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 'bold'
  },
  logo: {
    width:'200px'
  },
  rowStart: {
    margin: 10,
    padding: 10,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  row: {
    margin: 10,
    padding: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  left: {
    width: '50%'
  },
  right: {
    width: '50%',
    textAlign: 'right'
  },
  p: {
    fontSize: 12,
  },
  table: {
    margin: '20px 20px 0',
  },
  tr: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  td: {
    width: 120,
    fontSize: 12,
    textAlign: 'left',
    padding:5,
    border: 1
  },
  tdR: {
    width: 120,
    fontSize: 12,
    textAlign: 'right',
    padding:5,
    border: 1
  },
  th: {
    fontSize: 14,
    minWidth: 120,
    textAlign: 'left',
    fontWeight: 'bolder',
    padding:5,
    border: 1
  },
  thF: {
    minWidth: 310,
    fontSize: 14,
    textAlign: 'left',
    fontWeight: 'bold',
    padding:5,
    border: 1
  },
  tdF: {
    minWidth: 310,
    fontSize: 12,
    padding:5,
    border: 1
  },
  tdN: {
    minWidth: 310,
  },
  words: {
    fontSize: 20,
    fontWeight: 'bolder',
    textAlign: 'center',
    margin: '50px 20px',
    textTransform: 'uppercase'
  }
});

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      ones : ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'],
      tens : ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'],
      teens : ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen']
    };
  }

  // actual  conversion code starts here



convert_millions = (num) => {
  if (num >= 1000000) {
    return this.convert_millions(Math.floor(num / 1000000)) + " million " + this.convert_thousands(num % 1000000);
  } else {
    return this.convert_thousands(num);
  }
}

convert_thousands = (num) => {
  if (num >= 1000) {
    return this.convert_hundreds(Math.floor(num / 1000)) + " thousand " + this.convert_hundreds(num % 1000);
  } else {
    return this.convert_hundreds(num);
  }
}

convert_hundreds = (num) => {
  if (num > 99) {
    return this.state.ones[Math.floor(num / 100)] + " hundred " + this.convert_tens(num % 100);
  } else {
    return this.convert_tens(num);
  }
}

convert_tens = (num) => {
  if (num < 10) return this.state.ones[num];
  else if (num >= 10 && num < 20) return this.state.teens[num - 10];
  else {
    return this.state.tens[Math.floor(num / 10)] + " " + this.state.ones[num % 10];
  }
}

convert = (num) => {
  if (num == 0) return "zero";
  else return this.convert_millions(num);
}


  componentDidMount  = () => {
    getOneOrder({id : this.props.match.params.orderid}).then(resp => {
      this.setState({
        order:resp
      })
    }, err => {
      console.log(err);
    })
  }
  
  render() {
    const {order} = this.state;
    let subtotal = order != null ? order.subtotal - (order.subtotal*18/100) : 0;
    let tax = order != null ? order.subtotal - subtotal : 0;
    
    return (        
        <div className="invoicePage">
          {
          order == null ? <div></div> : 
          <PDFViewer>
            <Document>
              <Page size="A4" style={styles.page}>
                <Text style={styles.title}>Invoice</Text>
                <Text style={styles.centerP}>(#{order.order_id})</Text>
                <View style={styles.rowStart}>
                  <View style={styles.left}>
                    <Image src={"/logo.png"} style={styles.logo} />
                  </View>
                  <View style={styles.right}>
                    <Text style={styles.stitle}>Shipping Details:</Text>
                    <Text style={styles.p}>{order.name}</Text>
                    <Text style={styles.p}>{order.email}</Text>
                    <Text style={styles.p}>{order.phone}</Text>
                    <Text style={styles.p}>{order.address}</Text>
                    <Text style={styles.p}>{order.address2}</Text>
                  </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tr}>
                      <Text style={styles.thF}>
                        Item
                      </Text>
                      <Text style={styles.th}>
                        Qty
                      </Text>
                      <Text style={styles.th}>
                        Price
                      </Text>
                    </View>
                    <View style={styles.tr}>
                      <Text style={styles.tdF}>
                        {order.title}
                      </Text>
                      <Text style={styles.td}>
                        {order.qty}
                      </Text>
                      <Text style={styles.tdR}>
                        {subtotal}
                      </Text>
                    </View>
                    <View style={styles.tr}>
                      <Text style={styles.tdN}>
                        
                      </Text>
                      <Text style={styles.td}>
                        SGST (9%)
                      </Text>
                      <Text style={styles.tdR}>
                        {tax/2}
                      </Text>
                    </View>
                    <View style={styles.tr}>
                      <Text style={styles.tdN}>
                        
                      </Text>
                      <Text style={styles.td}>
                        CGST (9%)
                      </Text>
                      <Text style={styles.tdR}>
                        {tax/2}
                      </Text>
                    </View>
                    <View style={styles.tr}>
                      <Text style={styles.tdN}>
                        
                      </Text>
                      <Text style={styles.td}>
                        Sub Total
                      </Text>
                      <Text style={styles.tdR}>
                        {order.subtotal}
                      </Text>
                    </View>
                    <View style={styles.tr}>
                      <Text style={styles.tdN}>
                        
                      </Text>
                      <Text style={styles.td}>
                        Discount
                      </Text>
                      <Text style={styles.tdR}>
                        -{order.discount}
                      </Text>
                    </View>
                    <View style={styles.tr}>
                      <Text style={styles.tdN}>
                        
                      </Text>
                      <Text style={styles.td}>
                        Delivery Charge
                      </Text>
                      <Text style={styles.tdR}>
                        {order.shipping}
                      </Text>
                    </View>
                    <View style={styles.tr}>
                      <Text style={styles.tdN}>
                        
                      </Text>
                      <Text style={styles.td}>
                        Total
                      </Text>
                      <Text style={styles.tdR}>
                        {order.total}
                      </Text>
                    </View>
                </View>
                <Text style={styles.words}>
                  {this.convert(order.total)}
                </Text>
              </Page>
            </Document>
          </PDFViewer>
          }
        </div>
      
    );
  }
}

export default Invoice;