import React from 'react';
import { Link } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './style.scss';
import config from '../../config';

class Footer extends React.Component {
    
  render() {
    const path = window.location.pathname;
    if ( path == '/login' || path == '/register' || path == '/forgot-password' ) return null;
    return (
      <footer className="footer"><Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to={config.home}>
        {config.name}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography></footer>
    );
    }
}

export default Footer;