import React, { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Alert from '../../components/Alert';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ImagePicker from '../../components/ImagePicker';
import {updateDeliverySettings, getOneDeliverySettings} from '../../http/deliverysettings-requests';

import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

import {EditorState, convertToRaw, ContentState, convertFromHTML, createFromBlockArray} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import './style.scss';

export default function EditDeliverySettings(props) {
const id = 1;
const [email, setEmail] = useState("");
const [pass, setPass] = useState("");
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState("");


useEffect(() => {
    getSettings();
}, []);

const getSettings = () => {
  getOneDeliverySettings(1).then(resp => {
        setEmail(resp.email);
        setPass(resp.password);
      
        props.updatedAt(resp.updated_at);
    }, err => {
        console.log(err);
    })
}

const submit = (e) => {
    e.preventDefault();
    if(email != '' && pass != '' ){
    setLoading(true);
    var data = {
        id, 
        email,
        pass
    };
    updateDeliverySettings(data).then(resp => {
        console.log(resp)
      if (resp != '') {
          window.location.href = "/settings/delivery";
      }
    }, error => {
      console.log(error);
      setAlert(error.data.message);
      setLoading(false);
    });
}else{
    setAlert("Please fill all fields");
}
  };

return (
    
            <ValidatorForm onSubmit={submit}>
                <TextValidator
                    variant="outlined"
                    value = {email}
                    onChange={(e) => {setEmail(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Shiprocket Email"
                    autoFocus
                />
                <TextValidator
                    variant="outlined"
                    type="password"
                    fullWidth
                    value = {pass}
                    onChange={(e) => setPass(e.target.value)}
                    label="Shiprocket Password"
                    validators={['required']}
                    errorMessages={['This field is required']}
                />
                
               
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Update</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>
            
  );
}
