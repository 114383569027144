import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Moment from 'react-moment';
import Alert from '../../components/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReplyIcon from '@material-ui/icons/Reply';
import StarIcon from '@material-ui/icons/Star';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import EditIcon from '@material-ui/icons/Edit';
import config from '../../config';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import {getAllRatings, replyRating} from '../../http/rating-requests';
import './style.scss';
import { Box } from '@material-ui/core';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

function preventDefault(event) {
  event.preventDefault();
}


export default function BrandList() {

  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState('');
  const [open, setOpen] = useState('');
  const [reply, setReply] = useState('');
  const [replyId, setReplyId] = useState(0);
  
  useEffect(() => {
      getRows();
  }, []);

  const getRows = () => {
    getAllRatings().then(resp => {
      console.log(resp);
      setRows(resp);
    }, err => {
      
    })
  }


  const submitRating = () => {
    var data = {
      id: replyId,
      reply: reply
    };

    replyRating(data).then(resp => {
      setOpen(false);
      getRows();
    }, err => {
      setAlert(err.data.message);
    })
  }

  const openReply = (id) => {
    setReplyId(id);
    setOpen(true);
  }


  return (
    <div className="brand-list">
      <Alert alert={alert} setAlert={setAlert} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Rating</TableCell>
            <TableCell>Review</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Reply</TableCell>
            <TableCell>Replied At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 && rows.map((r, i) => {
              return <TableRow key={i}>
              <TableCell>
                
                <a href={config.home+"product/"+r.slug} target="_blank">
                <Box alignItems="center"  display="flex">
                  <img src={config.staticBase+r.thumbnail} alt={r.title} className="table-thumbnail" />
                  <span className="ml-2">{r.title}</span>
                </Box>
                </a>
              </TableCell>
              <TableCell>
                <Box alignItems="center"  display="flex">
                {r.rating} <StarIcon />
                </Box>
                
              </TableCell>
              <TableCell>{r.review}</TableCell>
              <TableCell><Moment date={r.created_at} format="DD-MM-YY hh:mm A" /></TableCell>
              <TableCell>{r.reply}</TableCell>
              <TableCell>
                {
                  r.reply_at != null && <Moment date={r.reply_at} format="DD-MM-YY hh:mm A" />
                }
              </TableCell>
              
              <TableCell>
                <IconButton aria-label="show 4 new mails" className="text-danger" onClick={() => openReply(r.id)}>
                  <ReplyIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            })
          }
        </TableBody>
      </Table>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add / Replace your reply"}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Add your review and ratings
          </DialogContentText> */}
          
          <TextField variant="outlined" onChange={(e) => setReply(e.target.value)} fullWidth value={reply} label="Reply" autoFocus />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={submitRating} color="primary">
            Submit
          </Button>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
