import React, { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Alert from '../../components/Alert';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ImagePicker from '../../components/ImagePicker';
import {updateOtherSettings, getOneOtherSettings} from '../../http/othersettings-requests';

import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

import {EditorState, convertToRaw, ContentState, convertFromHTML, createFromBlockArray} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import './style.scss';

export default function EditOtherSettings(props) {
const id = 1;
const [link, setLink] = useState("");
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState("");

useEffect(() => {
    getSettings();
}, []);

const getSettings = () => {
  getOneOtherSettings('whatsapp').then(resp => {
        setLink(resp.s_val);
        props.updatedAt(resp.updated_at);
    }, err => {
        console.log(err);
    })
}

const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    var data = {
        id,
        's_val': link,
    };
    updateOtherSettings(data).then(resp => {
      if (resp != '') {
          window.location.href = "/settings/other";
      }
    }, error => {
      console.log(error);
      setAlert(error.data.message);
      setLoading(false);
    });
  };

return (
    
            <ValidatorForm onSubmit={submit}>
                <TextValidator
                    variant="outlined"
                    value = {link}
                    onChange={(e) => {setLink(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Whatsapp Link"
                    autoFocus
                />
              
                {/* <Grid container>
                  <Grid item md={6}>
                  <div className="mb-1 sub-title">Logo:</div>
                <ImagePicker limit={1} id="logo" picked={setLogo} selected={logo} />
                  </Grid>
                  <Grid item md={6}>
                  <div className="mb-1 sub-title">Favicon:</div>
                <ImagePicker limit={1} id="favicon" picked={setFavicon} selected={favicon} />
                  </Grid>
                </Grid>
                

                 */}
               
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Update</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>
            
  );
}
