import {
    httpGet,
    httpPost,
    httpPut,
    httpDelete
} from "./http-service";
import config from '../config';

export const getAllOtherSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "generalsettings", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getNestedOtherSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "generalsettings-nested", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const getOneOtherSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "othersettings/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const deleteOtherSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpDelete(config.apiBase + "generalsettings/"+data, false)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const updateOtherSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpPut(config.apiBase + "othersettings/"+data.id, false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};

export const addOtherSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpPost(config.apiBase + "generalsettings", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};


export const getTopOtherSettings = (data) => {
    return new Promise((resolve, reject) => {
        httpGet(config.apiBase + "generalsettings-top", false, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            });
    });
};