const config = {
  name: "SaveUp",

  // home: "http://localhost:3000/",
  // apiBase: "http://localhost:3001/",
  // staticBase: "http://localhost:3001/uploads/",

  home: "https://saveup.co.in/",
  apiBase: "https://api.saveup.co.in/",
  staticBase: "https://api.saveup.co.in/uploads/",
};

export default config;
