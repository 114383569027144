import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link, useParams } from 'react-router-dom';
import Alert from '../../components/Alert';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ImagePicker from '../../components/ImagePicker';
import { getOneAdmin, updateAdmin } from '../../http/admin-requests';

export default function EditAdmin(props) {
  const { id } = useParams();
const [business, setBusiness] = useState("");
const [person, setPerson] = useState("");
const [phone, setPhone] = useState("");
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [slug, setSlug] = useState("");
const [lat, setLat] = useState("");
const [lon, setLon] = useState("");
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState("");

useEffect(() => {
    getAdmin();
}, []);

const getAdmin = () => {
    getOneAdmin(id).then(resp => {
      console.log(resp);
        setBusiness(resp.business);
        setPerson(resp.person);
        setPhone(resp.phone);
        setEmail(resp.email);
        setLat(resp.lat);
        setLon(resp.lon);
        setSlug(resp.slug);
    }, err => {
        console.log(err);
    })
}

const slugify = (txt) => {
    txt = txt.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
    setSlug(txt);
};

const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    var data = {
      business,
      person,
      phone,
      email,
      password,
      slug,
      lat,
      lon,
      id
    };
    updateAdmin(data).then(resp => {
        if (resp != '') {
            window.location.href = "/admins";
        }
      }, error => {
        console.log(error);
        setAlert(error.data.message);
        setLoading(false);
      });
  };

return (
    <div className="root">
      <Header />
      <main className="content">
        <Alert alert={alert} setAlert={setAlert} />
        <div className="verticle-spacer" />
        <Container maxWidth="lg" className="container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className="paper mb-2">
              <Box flexDirection="row" display="flex" justifyContent="space-between">
                <h1 className="page-title">Edit Admin</h1>
                <Link to="/admins"><Button variant="contained" color="secondary">Back</Button></Link>
              </Box>
            </Paper>
            <Paper className="paper">
            <ValidatorForm onSubmit={submit}>
            <TextValidator
                    variant="outlined"
                    value = {business}
                    onChange={(e) => {setBusiness(e.target.value); slugify(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    id="email"
                    label="Business / Store Name"
                    name="business"
                    autoComplete="business"
                    autoFocus
                />
                
                <TextValidator
                    variant="outlined"
                    fullWidth
                    name="slug"
                    value = {slug}
                    onChange={(e) => setSlug(e.target.value)}
                    label="URL Slug"
                    type="text"
                    id="slug"
                    validators={['required']}
                    errorMessages={['This field is required']}
                    autoComplete="slug"
                />
                
                <TextValidator
                    variant="outlined"
                    value = {person}
                    onChange={(e) => {setPerson(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Contact Person Name"
                    name="person"
                    autoComplete="person"
                    autoFocus
                />
                <TextValidator
                    variant="outlined"
                    value = {phone}
                    onChange={(e) => {setPhone(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Phone"
                    name="phone"
                    autoComplete="phone"
                    autoFocus
                />
                <TextValidator
                    variant="outlined"
                    value = {email}
                    onChange={(e) => {setEmail(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                
                <TextValidator
                    variant="outlined"
                    value = {password}
                    onChange={(e) => {setPassword(e.target.value);} }
                    validators={[]}
                    errorMessages={['This field is required']}
                    fullWidth
                    type="password"
                    label="Password"
                    name="password"
                    autoComplete="password"
                    autoFocus
                />
                <TextValidator
                    variant="outlined"
                    value = {lat}
                    onChange={(e) => {setLat(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Store Location Latitude"
                    name="lat"
                    autoComplete="lat"
                    autoFocus
                />
                <TextValidator
                    variant="outlined"
                    value = {lon}
                    onChange={(e) => {setLon(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Store Location Longitude"
                    name="lon"
                    autoComplete="lon"
                    autoFocus
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Update</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>
            </Paper>
            </Grid>
          </Grid>
          <Footer />
        </Container>
      </main>
    </div>
  );
}
