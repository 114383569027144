import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link, useParams } from 'react-router-dom';
import Alert from '../../components/Alert';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ImagePicker from '../../components/ImagePicker';
import { getOnePrize, updatePrize } from '../../http/prize-requests';

export default function EditPrize(props) {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [shares, setShares] = useState(1);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");

useEffect(() => {
    getPrize();
}, []);

const getPrize = () => {
    getOnePrize(id).then(resp => {
      console.log(resp);
        setName(resp.name);
        setAmount(resp.amount);
        setShares(resp.shares);
    }, err => {
        console.log(err);
    })
}

const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    var data = {
      name,
      amount,
      shares,
      id
    };
    updatePrize(data).then(resp => {
        if (resp != '') {
            window.location.href = "/prizes";
        }
      }, error => {
        console.log(error);
        setAlert(error.data.message);
        setLoading(false);
      });
  };

return (
    <div className="root">
      <Header />
      <main className="content">
        <Alert alert={alert} setAlert={setAlert} />
        <div className="verticle-spacer" />
        <Container maxWidth="lg" className="container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className="paper mb-2">
              <Box flexDirection="row" display="flex" justifyContent="space-between">
                <h1 className="page-title">Edit Prize</h1>
                <Link to="/prizes"><Button variant="contained" color="secondary">Back</Button></Link>
              </Box>
            </Paper>
            <Paper className="paper">
            <ValidatorForm onSubmit={submit}>
            
            <TextValidator
                    variant="outlined"
                    value = {name}
                    onChange={(e) => {setName(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Prize Name"
                    name="name"
                    autoComplete="name"
                    
                />
                <TextValidator
                    variant="outlined"
                    value = {amount}
                    onChange={(e) => {setAmount(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="Prize Amount"
                    name="amount"
                    autoComplete="amount"
                    
                />
                <TextValidator
                    variant="outlined"
                    value = {shares}
                    onChange={(e) => {setShares(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    label="No of Sharing"
                    name="shared"
                    autoComplete="shared"
                    
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Update</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>
            </Paper>
            </Grid>
          </Grid>
          <Footer />
        </Container>
      </main>
    </div>
  );
}
