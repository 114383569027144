import React, { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Alert from '../../components/Alert';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {getTopCategories, updateCategory, getOneCategory} from '../../http/category-requests';

import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function EditCategory(props) {
const { id } = useParams();
const [categories, setCategories] = useState([]);
const [title, setTitle] = useState("");
const [order, setOrder] = useState(0);
const [slug, setSlug] = useState("");
const [parent, setParent] = useState("");
const [parentValue, setParentValue] = useState("");
const [loading, setLoading] = useState(false);
const [alert, setAlert] = useState("");

useEffect(() => {
    getCategory();
    getCategories();
}, []);

const getCategory = () => {
    getOneCategory(id).then(resp => {
        setTitle(resp.title);
        setSlug(resp.slug);
        setParentValue(resp.parent);
        setParent(resp.parent);
        setOrder(resp.sort_order);
    }, err => {
        console.log(err);
    })
}

const getCategories = () => {
    getTopCategories().then(resp => {
        setCategories(resp);
    }, err => {
        console.log(err);
    })
}

const slugify = (txt) => {
    txt = txt.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
    setSlug(txt);
};

const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    var data = {
        id,
        title,
        slug,
        order,
        parent
    };
    updateCategory(data).then(resp => {
        if (resp != '') {
            window.location.href = "/categories";
        }
      }, error => {
        console.log(error);
        setAlert(error.data.message);
        setLoading(false);
      });
  };

return (
    <div className="root">
      <Header />
      <main className="content">
        <Alert alert={alert} setAlert={setAlert} />
        <div className="verticle-spacer" />
        <Container maxWidth="lg" className="container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className="paper mb-2">
              <Box flexDirection="row" display="flex" justifyContent="space-between">
                <h1 className="page-title">Edit Category</h1>
                <Link to="/categories"><Button variant="contained" color="secondary">Back</Button></Link>
              </Box>
            </Paper>
            <Paper className="paper">
            <ValidatorForm onSubmit={submit}>
                <TextValidator
                    variant="outlined"
                    value = {title}
                    onChange={(e) => {setTitle(e.target.value); slugify(e.target.value);} }
                    validators={['required']}
                    errorMessages={['This field is required']}
                    fullWidth
                    id="email"
                    label="Category Title"
                    name="title"
                    autoComplete="title"
                    autoFocus
                />
                <TextValidator
                    variant="outlined"
                    fullWidth
                    name="password"
                    value = {slug}
                    onChange={(e) => setSlug(e.target.value)}
                    label="URL Slug"
                    type="text"
                    id="slug"
                    validators={['required']}
                    errorMessages={['This field is required']}
                    autoComplete="slug"
                />
                <SelectValidator
                    variant="outlined"
                    fullWidth
                    value={parent}
                    onChange={(e) => {
                        setParent(e.target.value);
                    }}
                    name="parent"
                    id="parent"
                    label="Parent Category"
                    validators={['required']}
                    errorMessages={['This field is required']}
                >
                    <MenuItem value={0}>Top Level</MenuItem>
                    {
                        categories.length > 0 && categories.map((e, i) => {
                            return <MenuItem value={e.id} key={i}>{e.title}</MenuItem>
                        })
                    }
                </SelectValidator>
                <TextValidator
                    variant="outlined"
                    fullWidth
                    name="order"
                    value = {order}
                    onChange={(e) => setOrder(e.target.value)}
                    label="Sort Order"
                    type="text"
                    id="slug"
                    validators={['required']}
                    errorMessages={['This field is required']}
                    autoComplete="order"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    { !loading && <span>Update</span> }
                    { loading && <CircularProgress color="secondary" /> }
                </Button>
                </ValidatorForm>
            </Paper>
            </Grid>
          </Grid>
          <Footer />
        </Container>
      </main>
    </div>
  );
}
