// LOGIN
export const setLogin = (props, d) => {
    if (d.username === 'user' && d.password === 'password') {
        localStorage.setItem('admin_auth', d)
        props.history.push('/');
    }
}

// LOGOUT
export const logout = () => localStorage.removeItem('admin_auth')

// LOGIN STATUS
export const isLogin = () => {
    if (localStorage.getItem('admin_auth')) return true;
    return false;
}